import React  from 'react';
import NewNavBar from "../navbar/new_navbar.js";
import {FormattedMessage,useIntl} from 'react-intl';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import {config} from '../../Constants';
import Footer from "../footer/footer.js";
function Checkout() {
      const intl = useIntl();
      const urlParams = new URLSearchParams(window.location.search);
      const bookingId = urlParams.get('bookingId');
      alertify.set('notifier','position', 'top-right');
        fetch(config.url.BASE_URL+`Home/PaymentReturnSupport/${bookingId}`)
        .then(response => response.json())
        .then(result => {
                  if(result.succeeded){
                      alertify.success(intl.formatMessage({id: 'BookingConfirmMessage'}));
                      setTimeout(function () {
                          window.location.href='/';
                      }, 5000);
                  }
            } 
            );
  return (
    <div>
     <NewNavBar />
    <div className="pageD container">
           <div className="coverD">
               <h5 id="header" style={{color:"green"}}><FormattedMessage id="BookingConfirmMessage"/></h5>
              <div style={{textAlign:"-webkit-center",marginTop:"15px"}}>
                        <Footer />
                        </div>
            </div>
    </div>
    </div>
  )
}

export default Checkout
import React, { useState } from "react";
import NewNavBar from "../navbar/new_navbar.js";
import {Button} from 'react-bootstrap';
import {FormattedMessage,useIntl} from 'react-intl';
import { useLocation,useNavigate } from "react-router-dom";
import * as Functions from '../../functions/functions';
import Footer from "../footer/footer.js";
import Loading from "../loading/loading";
function Details() {
    const {state} = useLocation();
    console.log(state);
    const intl = useIntl();
    const [active, setActive] = useState(false);

    function GoToDashbord(){
        window.location.href='/dashboard';
    }
    
    function getService(item){
        return  <div style={{display:"flex",width:"100%"}}>
        <p style={{width:"80%"}}>{item.service.name}</p>
        <p style={{width:"20%",textAlign:"right"}}>{item.price} {state.currency}</p>
        </div>;
    }

    return (
        <div>
              <NewNavBar />
             <div className="pageD container">
                    <div className="coverD">
                        <h4 id="header"><FormattedMessage id="BookingDetails"/></h4>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"37%"}}><FormattedMessage id="Patient"/></b>
                        <p style={{width:"63%"}}>{state.patient.fullName}</p>
                        </div>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"37%"}}><FormattedMessage id="Email"/></b>
                        <p style={{width:"63%"}}>{state.patient.email}</p>
                        </div>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"37%"}}><FormattedMessage id="Birthday"/></b>
                        <p style={{width:"63%"}}>{Functions.fixDate(state.patient.bday)}</p>
                        </div>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"37%"}}><FormattedMessage id="PhoneNumber"/></b>
                        <p style={{width:"63%"}}>{state.patient.phoneNumber}</p>
                        </div>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"37%",marginBottom:"10px"}}><FormattedMessage id="Anamnesis"/></b>
                        <p style={{width:"63%"}}>{state.details}</p>
                        </div>
                    </div>
                    <div className="coverD">
                        <h4><FormattedMessage id="Services"/></h4>
                        <div style={{width:"100%"}}>
                        <div style={{display:"flex"}}>
                            <b style={{width:"80%"}}><FormattedMessage id="Name"/></b>
                            <b style={{width:"20%",textAlign:"right"}}><FormattedMessage id="Price"/></b>
                         </div>
                        <hr
                        style={{
                        background: '#727272',
                        color: '#727272',
                        borderColor: '#727272',
                        height: '3px',
                        }}
                    />
                        </div>
                        {state.services.map(item => (getService(item)))}
                        <br></br>
                       <div style={{width:"100%"}}>
                            <Button  variant="outline-secondary" style={{width:"100%"}} onClick={GoToDashbord} className="btn btn-dark-blue"><FormattedMessage id="GoToDashboard"/></Button>
                        </div>
                    </div>
                    <div className="coverD">
                    <div style={{textAlign:"-webkit-center"}}>
                                <Footer />
                                </div>
                    </div>
             </div>
             {active && ( <Loading />)}
        </div>
      
      );
  }
  export default Details;
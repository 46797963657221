import React, { useEffect } from "react";
import NewNavBar from "../navbar/new_navbar.js";
import {FormattedMessage} from 'react-intl';
import {Button} from 'react-bootstrap';
import {config} from '../../Constants';
import Footer from "../footer/footer.js";
function Response() {
    function GoToLogin(){
        window.location.href='/doctorlogin';
    }
   

    function getMessage(){
        const urlParams = new URLSearchParams(window.location.search);
        const confirm = urlParams.get('confirm');
        const cancel = urlParams.get('cancel');
        if(confirm==="true"){
            return <div style={{textAlign:"center"}}><h4 className="Confirm"><FormattedMessage id="Success"/></h4>
            <h6 className="Confirm"><FormattedMessage id="SuccessMessage"/></h6></div>;
        }else if(confirm==="false"){
            return <div style={{textAlign:"center"}}> <h4><FormattedMessage id="Error"/></h4>
            <h6 className="WarningLive"><FormattedMessage id="ErrorMessage"/></h6></div>;
        }
        if(cancel==="true"){
            const bookingId = urlParams.get('bookingId');
            return <div style={{textAlign:"center",width:"100%"}}><h4><FormattedMessage id="CancelBooking"/></h4>
            <h6><FormattedMessage id="CancellationReason"/></h6>
            <input id="ReasonId" type="text" className="form-control" />
            <div style={{marginTop:"10px"}}>
            <Button variant="primary" onClick={(e) => Close()} style={{marginRight:"10px"}} size="m"><FormattedMessage id="Close"/></Button>
            <Button variant="danger" onClick={(e) => cancelBooking(bookingId)} size="m"><FormattedMessage id="CancelBooking"/></Button>
            </div>
            </div>;
        }else if(cancel==="false"){
            return <div style={{textAlign:"center"}}> <h4><FormattedMessage id="Error"/></h4>
            <h6 className="WarningLive"><FormattedMessage id="ErrorMessage"/></h6></div>;
        }
        else if(cancel==="done"){
            return <div style={{textAlign:"center"}}>
            <h6 className="Confirm"><FormattedMessage id="CancelSuccess"/></h6></div>;
        }
    }
    function Close(){
        window.location.href = "/";
    }
    function cancelBooking(bookingId){
        var reason=document.getElementById("ReasonId").value;
        if(reason !==""){
          fetch(config.url.BASE_URL+`Home/CancelBooking/${bookingId}/${reason}`)
          .then(response => response.json())
          .then(data => {
            window.location.href = "/response?cancel=done";
            } 
            );
        }
    }
    function getLoginButton(){
        const urlParams = new URLSearchParams(window.location.search);
        const cancel = urlParams.get('cancel');
        if(cancel!=="true"){
        return  <div style={{width:"100%"}}>
        <Button  variant="outline-secondary" style={{width:"100%"}} onClick={GoToLogin} className="btn btn-dark-blue"><FormattedMessage id="Login"/></Button>
        </div>
        }
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const cancel = urlParams.get('cancel');
        if(cancel==="true"){
            document.getElementById("ReasonId").value=urlParams.get('reason');
        }
    }, [])
    

  return (
    <div>
     <NewNavBar />
    <div className="pageD container">
           <div className="coverD">
               {getMessage()}
               {getLoginButton()}
           </div>
           <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
    </div>
    </div>
  )
}

export default Response
import React, { useState } from "react";
import {Button, Form} from 'react-bootstrap';
import {FormattedMessage,useIntl} from 'react-intl';
import * as Functions from '../../functions/functions';
import {config} from '../../Constants';
import Footer from "../footer/footer.js";
import NewNavBar from "../navbar/new_navbar.js";
import Roles from '../../roles';
import Loading from "../loading/loading";
const DoctorLogin = () => {
    const intl = useIntl();
    const [active, setActive] = useState(false);
    const [account, setAccount] = useState({
        username: '',
        password: ''
      });
      const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        account[name] = value;
        setAccount(account);
      }
      function submitLogin(){
        setActive(true);
        document.getElementById('AlertMessage').innerHTML = '';
                fetch(config.url.API_URL+'user/login',{
                method:'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(account
                )
            })
            .then(res=>res.json())
            .then((result)=>{
                setActive(false);
                if(result==="Credentials incorrect"){
                   
                    document.getElementById('AlertMessage').innerHTML = intl.formatMessage({id: 'Credentialsincorrect'});
                }else if(Array.isArray(result)){
                    document.getElementById('AlertMessage').innerHTML = intl.formatMessage({id: 'InvalidLogin'});
                }
                else if(result.roles.includes(Roles.Patient)){
                    document.getElementById('AlertMessage').innerHTML = intl.formatMessage({id: 'LoginMessageForPatients1'}) + `<a href="/login">${intl.formatMessage({id: 'PatientPortal'})}</a>` + intl.formatMessage({id: 'LoginMessageForPatients2'});
                }
                else{
                    localStorage.setItem('access_token',result.token);
                    localStorage.setItem('publicKeyCookie',result.publicKeyCookie);
                    localStorage.setItem('privateKeyCookie',result.privateKeyCookie);
                    localStorage.setItem('encryptionTestCookie',result.encryptionTestCookie);
                    localStorage.setItem('roles',result.roles);
                    localStorage.setItem('userId',result.id);
                    if(result.culture===null){
                        localStorage.setItem('lang', "da");
                    }else{
                        localStorage.setItem('lang', result.culture.slice(0, 2));
                    }
                    window.location.href="/dashboard";
                }
            }
            )
      }

    return (
            <div>
                <NewNavBar />
            <div className="page container">
                    <div className="cover">
                        <Form className="align-center">
                        <h1 className="row-80"><FormattedMessage id="DoctorLogin"/></h1>
                        <h6 style={{marginBottom :"20px"}}><FormattedMessage id="LoginInfoDoctor"/><br></br><FormattedMessage id="LoginInfoDoctor1"/></h6>
                            <Form.Group controlId="Username">
                                <Form.Control  type="text" name="username" className="row" placeholder={Functions.useFormatMessage('Username')} onChange={handleChange} ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId="Password">
                                <Form.Control type="password" name="password" className="row" placeholder={Functions.useFormatMessage('Password')} onChange={handleChange} ></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                <Button type="button" id="PerformDoctorLoginButton" onClick={submitLogin} className="btn btn-dark-blue row-80" value="Login"><FormattedMessage id="LoginAsDoctor"/></Button>
                                <div className="alt-remember"><label className="alert-message" id="AlertMessage"></label></div>
                                <div className="remember-forgot" style={{marginBottom:"10px"}}>
                                <input type="checkbox" style={{marginTop:"-10px",marginRight:"10px"}} value="lsRememberMe" id="rememberMe"/> <label for="rememberMe" style={{marginRight:"10px"}}><FormattedMessage id="Rememberme"/></label><a  href="/forgotpassword"><FormattedMessage id="Forgotpassword"/></a>
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="cover">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
            </div>
            {active && ( <Loading />)}
            </div>
                )
  };
  export default DoctorLogin;
import React, { useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import axios from "../../context/axios_instance";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { FaCalendarMinus,FaCalendarCheck,FaCalendarTimes,FaCheckCircle,FaFileAlt,FaComment,FaLocationArrow,FaFileDownload } from "react-icons/fa";
import {FormattedMessage} from 'react-intl';
import * as Functions from '../../functions/functions';
import Popup from "../popups/popup";
function PatientBookingList() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    let [content, setContent] = useState(null);  
    const [patientFName, setPatientFName] = useState(null);  
    const [patientLName, setPatientLName] = useState(null); 
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
        setIsOpen(!isOpen);
      }
    const [origin, setOrigin] = useState();
    var arrayMonth = ['','JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    useEffect(() => {
        axios.get('Patient/Home/PatientDashboard')
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result.data.bookings);
            setPatientFName(Functions.decryptString(result.data.firstName));
            setPatientLName(Functions.decryptString(result.data.lastName));
          },
          (error) => {
            setIsLoaded(true);
          }
        )
    }, [])
     
    function downloadInvoice(bookingId,invoiceId){
        axios.get(`Patient/Home/GetInvoice/${bookingId}/${invoiceId}`)
        .then(
          (result) => {
            if(result.data !== ""){
                window.location.href= result.data;
            }
          }
        )
    }
    function openChat(bookingId,name){
        navigate("/chat", { state: { id: bookingId, name: name } });
    }

    function getMonth(month){
        if(month<10){
            month = month.substring(1,2);
        }
        return arrayMonth[month];
    }
 
    function getStatus(item){
        if(item.status === 8 ){
            return <FormattedMessage id="Cancelled"/>;
        }else{
            return `${item.title}`;
        }
    }
    
    function getStatusOrDiagnosis(item){
        if(item.status === 8){
            return <FormattedMessage id="Status"/>;
        }else{
            return <FormattedMessage id="Diagnosis"/>;
        }
    }
    function getColor(item){
        if(item.status === 8 || item.status === 2 || item.status === 7 ){
           return "#dc3545";
        }else if(item.status === 4){
            return "rgb(6 185 11)";
        }
        else if(item.status === 0){
            return "rgb(237 178 0)";
        }
        else if(item.status === 5){
            return "#0d793f";
        }
        else{
            return "#007bff";
        }
    }

    function cancelBooking(bookingId){
          setIsOpen(false);
          var firstLetterOfLastName=patientLName[0];
          axios.get(`Patient/Home/CancelBooking/${bookingId}/${patientFName}/${firstLetterOfLastName}`)
          .then(
            (result) => {
              document.getElementById("cancel" + bookingId).style.display="none";
            }
          )
    }

    function cancelBookingPopup(bookingId,isCancellationAllowed){
        var message = <FormattedMessage id="SureCancellation"/>;
        if(!isCancellationAllowed){
            message = <FormattedMessage id="CancellationFeeMessage"/>;
        }
        content = <div>
                    <div className="modal-header bg-dark-blue">
                        <h6 className="modal-title"><FormattedMessage id="CancelBooking"/></h6>
                    </div>
                    <div className="modal-body text-center">
                        <b style={{color: "#0c4a7c"}}>
                           {message}
                        </b>
                    </div>
                    <div className="modal-footer">
                    <Button variant="danger" id={"cancelPopup"+bookingId} onClick={(e) => cancelBooking(bookingId)}  size="sm"><FormattedMessage id="Cancel"/></Button>
                    </div>
                </div>;
         setContent(content);
        setIsOpen(!isOpen);
    }
    
    useEffect(() => {
        try {
            navigator.geolocation.getCurrentPosition(function(position) {
            const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
          let currentAddress;
           const google = window.google;
            var geocoder = new google.maps.Geocoder();
            let google_map_position = new google.maps.LatLng(pos.lat, pos.lng);
            geocoder.geocode(
                { 'latLng': google_map_position },
                function (results, status) {
                    currentAddress = results[0].formatted_address;
                    setOrigin(currentAddress);
                    
                });
          });
           } catch (exceptionVar) {
             window.location.reload();
           }
      }, [])
    function openMap(address) {
        if(address != null){
            window.open(`https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(address.address)}&travelmode=driving`, "_blank");
        }
    }

    function GetNoBookingDiv(){
        if(items.length===0){
            return <div style={{marginLeft:"10px"}}><FormattedMessage id="NoBooking"/></div>;
        }
    }

    function getIC(item){
        if(item.insuranceCompanyId!=null){
            return  <div><b><FormattedMessage id="InsuranceCompany"/>:</b> {item.insuranceCompany.name} <br></br> </div>;
        }
        else{
            return "";
        }
    }

    function getButtons(item){
        if(item.status===0 || item.status===1 || item.status===3){
            if(item.unreadMessagesCount !==0){
                return <div style={{width:"100%"}}>
                    <Button variant="danger" style={{width:"100%",marginBottom:"5px"}} size="m" id={"cancel"+item.bookingId} onClick={(e) => cancelBookingPopup(item.bookingId,item.isCancellationAllowed)} ><FaCalendarTimes /></Button>
                    <Button variant="primary" style={{width:"100%",marginBottom:"5px"}} size="m" data-chat={"chat_"+item.bookingId} onClick={(e) => openChat(item.bookingId,item.doctor.fullName)}><FaComment /></Button><span className="chat-notification">{item.unreadMessagesCount}</span>
                    <Button variant="dark" style={{width:"100%"}} size="m" onClick={(e) => openMap(item.doctor.address)}><FaLocationArrow /></Button></div>;
            }else{
              return <div style={{width:"100%"}}>
                    <Button variant="danger" style={{width:"100%",marginBottom:"5px"}} size="m" id={"cancel"+item.bookingId} onClick={(e) => cancelBookingPopup(item.bookingId,item.isCancellationAllowed)} ><FormattedMessage id="Cancel"/><FaCalendarTimes style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>
                    <Button variant="primary" style={{width:"100%",marginBottom:"5px"}} size="m" data-chat={"chat_"+item.bookingId} onClick={(e) => openChat(item.bookingId,item.doctor.fullName)}><FormattedMessage id="Chat"/><FaComment style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>
                    <Button variant="dark" style={{width:"100%"}} size="m" onClick={(e) => openMap(item.doctor.address)}><FormattedMessage id="Directions"/><FaLocationArrow style={{marginLeft:"5px",marginTop:"-4px"}}/></Button></div>;
             }
        }
    }
    function getBookingStatus(item){
        var status =item.status;
            switch (status) {
            case 3:
                return <div style={{color:"rgb(237 178 0)"}}><b><FormattedMessage id="Status"/></b> <FormattedMessage id="Not confirmed"/></div>;
            case 1:
                return <div style={{color:"#198754"}}><b><FormattedMessage id="Status"/></b> <FormattedMessage  id="Confirmed"/></div>;
            case 2:
                return <div style={{color:"rgb(220, 53, 69);"}}><b><FormattedMessage id="Status"/></b> <FormattedMessage id="CancelledByDoctor"/></div>;
            case 0:
                 return <div style={{color:"rgb(220, 53, 69);"}}><b><FormattedMessage id="Status"/></b> <FormattedMessage id="BookingNotPaid"/></div>;
            case 7:
                return <div style={{color:"rgb(220, 53, 69);"}}><b><FormattedMessage id="Status"/></b> <FormattedMessage id="CancelledByPatient"/></div>;
            case 4:
                if(item.insuranceCompany.name ===null){
                    return <Button variant="success" style={{width:"100%",marginBottom:"2px"}} onClick={(e) => downloadInvoice(item.bookingId, item.invoiceId)}  size="m"><FormattedMessage id="Download Invoice"/><FaFileDownload style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>;
                }
                break;
            case 5:
                return <div style={{color:"#198754"}}><b><FormattedMessage id="Status"/></b> <FormattedMessage id="Finalized"/></div>;
                  default:
                break;
            }
        
    }


    if (!isLoaded) {
      return <div><FormattedMessage id="Loading"/></div>;
    } else {
      return (
        <div>
            <a href="/" style={{width:"-webkit-fill-available", marginBottom:"10px"}} className="btn btn-dark-blue new-booking-btn"><FormattedMessage id="NewBooking"/></a>
            {GetNoBookingDiv()}
              <Accordion>
          {items.map(item => (
            <Accordion.Item eventKey={item.bookingId}>
            <Accordion.Header><div data-bookingdate={item.start} data-bookingid={item.bookingId} style={{color: getColor(item)}}>{item.start.substring(8, 10)} {getMonth(item.start.substring(5,7))} - {item.doctor.fullName}</div></Accordion.Header>
            <Accordion.Body style={{color: getColor(item)}}>
             <b><FormattedMessage id="Time"/>:</b> {item.start.substring(11,16)} - {item.end.substring(11,16)}<br></br>
             {getIC(item)}
             <b><FormattedMessage id="Speciality"/>:</b> {item.doctor.speciality.name}<br></br>
             <b>{getStatusOrDiagnosis(item)}</b> {getStatus(item)}<br></br>
             <b><FormattedMessage id="Price"/>:</b> {item.price} {item.doctor.country.culture.numberFormat.currencySymbol}<br></br>
             <b><FormattedMessage id="DoctorAddress"/>:</b> {item.doctor.address.address}<br></br>
             <div style={{marginBottom:"4px"}}>{getBookingStatus(item)}</div>
             {getButtons(item)}
            </Accordion.Body>
        </Accordion.Item>
          ))}
        </Accordion>
        {isOpen && <Popup
            content={content}
            handleClose={togglePopup}
            />}
        </div>
      
      );
    }
  }
  export default PatientBookingList;
import React , { useState ,useRef, useEffect} from 'react';
import NewNavBar from "../../navbar/new_navbar.js";
import {FormattedMessage,useIntl} from 'react-intl';
import {Button, Form} from 'react-bootstrap';
import * as Functions from '../../../functions/functions';
import axios from "../../../context/axios_instance";
import 'react-datepicker/dist/react-datepicker.css';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import Map from '../../map/map';
import Select from 'react-select';
import Footer from '../../footer/footer.js';
const account= {
        firstName: '',
        lastName: '',
        fullName: '',
        email: '',
        encryptedEmail: '',
        unencryptedEmail: '',
        encryptedFirstName: '',
        encryptedLastName: '',
        phoneNumber: '',
        gender: '',
        doctors:[],
        birthday: '',
        cPRNummer:'',
        citizenship:'',
        googleAddress: '',
        patientId:'',
        isEmailConfirmed:''

};
let countries=[];
function PatientProfile() {
  const intl = useIntl();
  const [cAddress, setCAddress] = useState(null);
  const [citizenship, setCitizenship] = useState([]);
  const [selectedCitizenship, setSelectedCitizenship] = useState([]);
  alertify.set('notifier','position', 'top-right');
      useEffect(() => {
        axios.get('Patient/Account/GetPatientProfile')
        .then(
          (result) => {
            account.doctors=result.data.doctors;
            console.log(account.doctors);
            account.isEmailConfirmed=result.data.isEmailConfirmed;
            account.patientId=result.data.patientId;
            setCitizenship(result.data.countryList);
            var citizenshipList="";
            if(result.data.citizenship !==null){
               citizenshipList=Functions.decryptString(result.data.citizenship);
               if(citizenshipList.includes("63")){
                document.getElementById("CPRNummerDiv").classList.remove("d-none");
              }
              var citizenshipArray = citizenshipList.split(',');
              var oldSelectedCitizenship=[]
              citizenshipArray.forEach(element => {
                oldSelectedCitizenship.push(result.data.countryList.find(a=>a.value==element));
                countries.push(parseInt(element));
              });
              setSelectedCitizenship(oldSelectedCitizenship);
            }
           document.getElementById('firstNameId').value=Functions.decryptString(result.data.firstName);
           if(result.data.cprNummer !== null){
            document.getElementById('CPRNummerId').value=Functions.decryptString(result.data.cprNummer);
           }
           document.getElementById('lastNameId').value=Functions.decryptString(result.data.lastName);
           document.getElementById('emailId').value=Functions.decryptString(result.data.email);
           if(result.data.googleAddress !== null){
            document.getElementById('AddressId').value=Functions.decryptString(result.data.googleAddress);
            setCAddress(Functions.decryptString(result.data.googleAddress));
           }
           if(result.data.phoneNumber !== null){
            document.getElementById('phoneNumberId').value=Functions.decryptString(result.data.phoneNumber);
           }
          
           var date = Functions.decryptString(result.data.birthday);
           date =Functions.fixDate(date);
           document.getElementById('birthdayId').value=date;
           document.getElementById('Gender').value=result.data.gender;
          }
        )
      }, []);
      const searchCitizenship = (e) => {
        countries=[];
        var counter=0;
        for(var i=0;i<e.length;i++){
          if(e[i].value===63){
            document.getElementById("CPRNummerDiv").classList.remove("d-none");
            counter++;
          }
          countries.push(e[i].value);
        }
        if(counter===0){
          document.getElementById("CPRNummerDiv").classList.add("d-none");
          document.getElementById('CPRNummerId').value="";
        }
        setSelectedCitizenship(e);
      }

      const editProfile = (e) => {
        e.preventDefault();
        document.getElementById('firstNameIdError').innerHTML="";
        document.getElementById('lastNameIdError').innerHTML="";
        document.getElementById('emailIdError').innerHTML="";
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let fName = document.getElementById('firstNameId').value;
        let lName = document.getElementById('lastNameId').value;
        let email = document.getElementById('emailId').value;
        if(fName==="" || fName.length < 3){
          document.getElementById('firstNameIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
        }
        else if(lName==="" || lName.length < 3){
          document.getElementById('lastNameIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
        }
        else if(email===""){
          document.getElementById('emailIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
        }
        else if(!emailRegex.test(email)){
          document.getElementById('emailIdError').innerHTML=intl.formatMessage({id: 'EmailNotValid'});
        }
        else if(!isCPRValid()){
          document.getElementById('CPRNummerIdError').innerHTML=intl.formatMessage({id: 'CprNotValid'});
        }else{
        editForm();
        }
      }

      function isCPRValid(){
        var valid=true;
        if(!document.getElementById("CPRNummerDiv").classList.contains("d-none")){
          let cpr = document.getElementById('CPRNummerId').value;
          let gender=document.getElementById('Gender').value;
          let bday = document.getElementById('birthdayId').value;
          if(!Functions.validateCPRNumber(cpr,bday,gender)){
                valid=false;
            }
        }
        return valid;
      }


      function editForm(){
        account.birthday=Functions.encryptString(document.getElementById('birthdayId').value);
        account.email=Functions.encryptString(document.getElementById('emailId').value);
        account.firstName=Functions.encryptString(document.getElementById('firstNameId').value);
        account.fullName=Functions.encryptString(document.getElementById('firstNameId').value + " " + document.getElementById('lastNameId').value);
        account.googleAddress=Functions.encryptString(document.getElementById('AddressId').value);
        account.lastName=Functions.encryptString(document.getElementById('lastNameId').value);
        account.phoneNumber=Functions.encryptString(document.getElementById('phoneNumberId').value);
        account.gender=parseInt(document.getElementById('Gender').value);
        account.cPRNummer = Functions.encryptString(document.getElementById('CPRNummerId').value);
        var citizenship = "";
        if(countries.length !==0){
          countries.forEach(element => {
            citizenship+=element.toString()+",";
            });
            citizenship = citizenship.substring(0, citizenship.length - 1);
        }
        account.citizenship = Functions.encryptString(citizenship);
        account.unencryptedEmail=document.getElementById('emailId').value;
        account.encryptedEmail=Functions.encryptStringWithoutTags(document.getElementById('emailId').value);
        account.encryptedFirstName=Functions.encryptStringWithoutTags(document.getElementById('firstNameId').value);
        account.encryptedLastName=Functions.encryptStringWithoutTags(document.getElementById('lastNameId').value);
        account.doctors.forEach(docPatLink => {
          docPatLink.firstName=Functions.encryptStringForDoctor(document.getElementById('firstNameId').value,docPatLink.doctor.user.key.publicKey);
          docPatLink.email=Functions.encryptStringForDoctor(document.getElementById('emailId').value,docPatLink.doctor.user.key.publicKey);
          docPatLink.lastName=Functions.encryptStringForDoctor(document.getElementById('lastNameId').value,docPatLink.doctor.user.key.publicKey);
          docPatLink.phoneNumber=Functions.encryptStringForDoctor(document.getElementById('phoneNumberId').value,docPatLink.doctor.user.key.publicKey);
          docPatLink.cPRNummer=Functions.encryptStringForDoctor(document.getElementById('CPRNummerId').value,docPatLink.doctor.user.key.publicKey);
          docPatLink.citizenship=Functions.encryptStringForDoctor(citizenship,docPatLink.doctor.user.key.publicKey);
        });
        console.log(account);
        axios.post(`Patient/Account/EditPatient`, account)
        .then(
          (result) => {
            console.log(result.data);
            alertify.success(intl.formatMessage({id: 'ProfileSaved'}));
            setCAddress(document.getElementById('AddressId').value);
          }
        )
      }

      const AutoComplete = () => {
        const autoCompleteRef = useRef();
        const inputRef = useRef();
        const options = {
          // componentRestrictions: { country: "dk" },
          types: ["geocode"]
          };
        useEffect(() => {
          try {
            setTimeout(() => {
            if(cAddress !== null){
              document.getElementById('AddressId').value=cAddress;
            }
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(
              inputRef.current,
              options
             );
            }, 500);
          } catch (exceptionVar) {
            console.log(exceptionVar);
          }
       
        }, []);
        return (
           <Form.Control  type="text" name="googleAddress" id='AddressId' autoComplete='off' ref={inputRef} className="row-Register" placeholder={Functions.useFormatMessage('Address')} ></Form.Control>
        );
      };
      function fixCPRFormat(){
        let cpr = document.getElementById('CPRNummerId').value;
        if(cpr.length ===10 && !cpr.includes("-")){
          document.getElementById('CPRNummerId').value = cpr.substr(0,6) +"-"+ cpr.substr(6,4);
        }
      }

  return (
    <div> 
         <NewNavBar />
        <div className="pageD container">
            <div className="coverD">
                <h4><FormattedMessage id="Profile"/></h4>
                <Form className="register-form-style" onSubmit={editProfile}>
             <h6 className="row-80"><FormattedMessage id="PersonalInformation"/></h6>
                    <h6 className="row-80"><FormattedMessage id="FirstName"/></h6>
                 <Form.Group >
                     <Form.Control  type="text" name="firstName" id='firstNameId' autoComplete='off' className="row-Register-without-margins" placeholder={Functions.useFormatMessage('FirstName')} ></Form.Control>
                     <div className="alt-remember-register"><span id="firstNameIdError"></span></div>
                     </Form.Group>

                    <h6 className="row-80"><FormattedMessage id="LastName"/></h6>
                     <Form.Group >
                     <Form.Control  type="text" name="lastName" id='lastNameId' autoComplete='off' className="row-Register-without-margins" placeholder={Functions.useFormatMessage('LastName')}  ></Form.Control>
                    <div className="alt-remember-register"><span id="lastNameIdError"></span></div>
                     </Form.Group>

                    <h6 className="row-80"><FormattedMessage id="Email"/></h6>
                     <Form.Group>
                     <Form.Control  type="text" name="email" autoComplete='off' id='emailId' className="row-Register-without-margins" placeholder={Functions.useFormatMessage('Email')} ></Form.Control>
                     <div className="alt-remember-register"><span id="emailIdError"></span></div>
                     </Form.Group>

                    <h6 className="row-80"><FormattedMessage id="PhoneNumber"/></h6>
                     <Form.Group >
                     <Form.Control  type="text" name="phoneNumber" id='phoneNumberId' autoComplete='off' className="row-Register" placeholder={Functions.useFormatMessage('PhoneNumber')} ></Form.Control>
                     </Form.Group>

                     <Form.Group style={{marginBottom:"15px"}}>
                            <h6 className="row-80-h6"><FormattedMessage id="Citizenship"/></h6>
                            <Select className="row-80-h6" id='CitizenshipId'  options={citizenship} isMulti placeholder={<FormattedMessage id="SelectCitizenship"/>} value={selectedCitizenship} onChange={searchCitizenship}/>
                     </Form.Group>

                      <Form.Group className='d-none' id='CPRNummerDiv'>
                        <h6 className="row-80-h6"><FormattedMessage id="CPRNummer"/></h6>
                        <Form.Control  type="text"autoComplete='off' id="CPRNummerId" className="row-Register" onChange={fixCPRFormat}></Form.Control>
                        <div className="alt-remember-register"><span id="CPRNummerIdError"></span></div>
                     </Form.Group>

                     <h6 className="row-80"><FormattedMessage id="Gender"/></h6>
                     <Form.Select className="row-Register" id='Gender'>
                        <option value="0"><FormattedMessage id="Male"/></option>
                        <option value="1"><FormattedMessage id="Female"/></option>
                        </Form.Select>

                        <Form.Group >
                    <h6 className="row-80"><FormattedMessage id="Birthday"/></h6>
                     <Form.Control  type="text" disabled='disabled' name="birthday" id='birthdayId' autoComplete='off' className="row-Register" placeholder={Functions.useFormatMessage('Birthday')} ></Form.Control>
                     </Form.Group>
                     
                    <h6 className="row-80"><FormattedMessage id="Address"/></h6>
                     <Form.Group >
                     <AutoComplete />
                  </Form.Group>
                  <div  style={{marginBottom:"30px"}}><Map address={cAddress}/></div>
                  <Button type="submit" className="btn btn-dark-blue row-80" value="Edit"><FormattedMessage id="Save"/></Button>
             </Form>
            </div>
            <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
        </div>
    </div>
    
  )
}

export default PatientProfile
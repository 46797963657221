import React , { useState ,useRef, useEffect} from 'react';
import { useLocation,useNavigate  } from "react-router-dom";
import axios from "../../../context/axios_instance";
import NewNavBar from "../../navbar/new_navbar.js";
import {FormattedMessage,useIntl} from 'react-intl';
import {Button, Form} from 'react-bootstrap';
import * as Functions from '../../../functions/functions';
import {config} from '../../../Constants';
import Select from 'react-select';
// import DatePicker, { registerLocale } from "react-datepicker";
// import 'react-datepicker/dist/react-datepicker.css';
// import da from "date-fns/locale/da";
// import en from "date-fns/locale/en-GB";
import { getYear } from 'date-fns';
import range from "lodash/range";
import guid from 'guid';
import Footer from '../../footer/footer';
import Loading from "../../loading/loading";
 let account = {
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        gender: '',
        birthday: '',
        googleAddress: '',
        publicKey:'',
        cPRNummer:'',
        citizenship:'',
        privateKey: '',
        encryptedTestText: '',
        unencryptedEmail:'',
        unencryptedName:'',
        unencryptedPassword:""
      };
let countries=[]; 
function Register() {
  const cryptico = window.cryptico;
  const intl = useIntl()
  const {state} = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [citizenship, setCitizenship] = useState([]);
  const [selectedCitizenship, setSelectedCitizenship] = useState([]);
  const Guid = guid.create().value;
  const Bits = 512;
  const privateKey = cryptico.generateRSAKey(Guid, Bits);
  const publicKey = cryptico.publicKeyString(privateKey);
  const encryptedTestText = cryptico.encrypt("Here2Treat", publicKey);
  const [day, setDay] = useState("1");
  const [month, setMonth] = useState("01");
  const [year, setYear] = useState("1970");
  let language = localStorage.getItem('lang'); 
  let defaultDate="01-01-1970";
  if(language==='en'){
    defaultDate="01.01.1970";
  }
  const [date, setDate] = useState(defaultDate);
  const [cAddress, setCAddress] = useState(null);
  // const handleChangeDate = date => setDate(date);
      const currentYear = getYear(new Date());
      const oldestYear = currentYear - 120;
      const years = range(oldestYear, currentYear + 1, 1);
      const days = range(1,32);
      let months = [{"key":"01","value":"January"},
      {"key":"02","value":"February"},
      {"key":"03","value":"March"},
      {"key":"04","value":"April"},
      {"key":"05","value":"May"},
      {"key":"06","value":"June"},
      {"key":"07","value":"July"},
      {"key":"08","value":"August"},
      {"key":"09","value":"September"},
      {"key":"10","value":"October"},
      {"key":"11","value":"November"},
      {"key":"12","value":"December"}
      ];
      if(language==='da'){
        // registerLocale("language", da); 
        months = [{"key":"01","value":"Januar"},
        {"key":"02","value":"Februar"},
        {"key":"03","value":"Marts"},
        {"key":"04","value":"April"},
        {"key":"05","value":"Maj"},
        {"key":"06","value":"Juni"},
        {"key":"07","value":"Juli"},
        {"key":"08","value":"August"},
        {"key":"09","value":"September"},
        {"key":"10","value":"Oktober"},
        {"key":"11","value":"November"},
        {"key":"12","value":"December"}
        ];
      }
      // else{
      //   registerLocale("language", en); 
      // }

      
      const registerPatient = (e) => {
        e.preventDefault();
        document.getElementById('firstNameIdError').innerHTML="";
        document.getElementById('lastNameIdError').innerHTML="";
        document.getElementById('emailIdError').innerHTML="";
        document.getElementById('passwordIdError').innerHTML="";
        document.getElementById('confirmEmailIdError').innerHTML="";
        document.getElementById('confirmPasswordIdError').innerHTML="";
        document.getElementById('birthdayIdError').innerHTML="";
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let passwordRegex =  new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
        let fName = document.getElementById('firstNameId').value;
        let lName = document.getElementById('lastNameId').value;
        let bday = document.getElementById('birthdayId').value;
        let password = document.getElementById('passwordId').value;
        let cPassword = document.getElementById('confirmPasswordId').value;
        let email = document.getElementById('emailId').value;
        let cEmail = document.getElementById('confirmEmailId').value;
        if(fName==="" || fName.length < 3){
          document.getElementById('firstNameIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
          document.getElementById('header').scrollIntoView();
        }
        else if(lName==="" || lName.length < 3){
          document.getElementById('lastNameIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
          document.getElementById('header').scrollIntoView();
        }
        else if(email===""){
          document.getElementById('emailIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
          document.getElementById('header').scrollIntoView();
        }
        else if(!emailRegex.test(email)){
          document.getElementById('emailIdError').innerHTML=intl.formatMessage({id: 'EmailNotValid'});
          document.getElementById('header').scrollIntoView();
        }
        else if(email!==cEmail){
          document.getElementById('confirmEmailIdError').innerHTML=intl.formatMessage({id: 'EmailDontMatch'});
          document.getElementById('header').scrollIntoView();
        }
        else if(password===""){
          document.getElementById('passwordIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
          document.getElementById('header').scrollIntoView();
        }
        else if(!passwordRegex.test(password)){
          document.getElementById('passwordIdError').innerHTML=intl.formatMessage({id: 'PasswordDontMatch'});
          document.getElementById('header').scrollIntoView();
        }
        else if(password!==cPassword){
          document.getElementById('confirmPasswordIdError').innerHTML=intl.formatMessage({id: 'PasswordFormatError'});
          document.getElementById('header').scrollIntoView();
        }
        else if(!isValideDate(bday)){
          document.getElementById('birthdayIdError').innerHTML=intl.formatMessage({id: 'InvalidDate'});
        }
        else if(!isCPRValid()){
          document.getElementById('CPRNummerIdError').innerHTML=intl.formatMessage({id: 'InvalidCPR'});
        }else{
        registerForm();
        }
      }
      function isCPRValid(){
        var valid=true;
        if(!document.getElementById("CPRNummerDiv").classList.contains("d-none")){
          let cpr = document.getElementById('CPRNummerId').value;
          let gender=document.getElementById('Gender').value;
          let bday = document.getElementById('birthdayId').value;
          if(!Functions.validateCPRNumber(cpr,bday,gender)){
                valid=false;
            }
        }
        return valid;
      }

      function fixCPRFormat(){
        let cpr = document.getElementById('CPRNummerId').value;
        if(cpr.length ===10 && !cpr.includes("-")){
          document.getElementById('CPRNummerId').value = cpr.substr(0,6) +"-"+ cpr.substr(6,4);
        }
      }
      
      function isValideDate(date){
        var splitedChar="-";
        if(language==='en'){
          splitedChar=".";
        }
        var arrDate = date.split(splitedChar);
        if(arrDate.length!==3)return false;
        var month = parseInt(arrDate[1],10);
        var day = parseInt(arrDate[0],10);
        var year = parseInt(arrDate[2],10);                                  
        var dateComp = new Date(year, month-1, day);
        return (month == dateComp.getMonth()+1 &&
                day == dateComp.getDate() && 
                year == dateComp.getFullYear());
      }
  

      function registerForm(){
        setActive(true);
        var citizenship = "";
        if(countries.length !==0){
          countries.forEach(element => {
            citizenship+=element.toString()+",";
            });
            citizenship = citizenship.substring(0, citizenship.length - 1);
        }
        account.birthday="<encrypt>"+cryptico.encrypt(document.getElementById('birthdayId').value, publicKey).cipher+"</encrypt>";
        account.googleAddress="<encrypt>"+cryptico.encrypt(document.getElementById('AddressId').value, publicKey).cipher+"</encrypt>";
        account.gender=document.getElementById('Gender').value;
        account.unencryptedEmail=document.getElementById('emailId').value;
        account.unencryptedPassword=document.getElementById('passwordId').value;
        account.privateKey =serializeRSAKey(privateKey);
        account.publicKey=publicKey;
        account.encryptedTestText=encryptedTestText.cipher;
        account.firstName = "<encrypt>"+cryptico.encrypt(document.getElementById('firstNameId').value, publicKey).cipher+"</encrypt>";
        account.cPRNummer = "<encrypt>"+cryptico.encrypt(document.getElementById('CPRNummerId').value, publicKey).cipher+"</encrypt>";
        account.citizenship = "<encrypt>"+cryptico.encrypt(citizenship, publicKey).cipher+"</encrypt>";
        account.lastName = "<encrypt>"+cryptico.encrypt(document.getElementById('lastNameId').value,publicKey).cipher+"</encrypt>";
        account.email = "<encrypt>"+cryptico.encrypt(document.getElementById('emailId').value, publicKey).cipher+"</encrypt>";
        account.confirmEmail = "<encrypt>"+cryptico.encrypt(document.getElementById('confirmEmailId').value, publicKey).cipher+"</encrypt>";
        account.password = "<encrypt>"+cryptico.encrypt(document.getElementById('passwordId').value, publicKey).cipher+"</encrypt>";
        account.confirmPassword = "<encrypt>"+cryptico.encrypt(document.getElementById('confirmPasswordId').value, publicKey).cipher+"</encrypt>";
        account.phoneNumber = "<encrypt>"+cryptico.encrypt(document.getElementById('phoneNumberId').value, publicKey).cipher+"</encrypt>";
        account.unencryptedName = document.getElementById('firstNameId').value + " " + document.getElementById('lastNameId').value;
        account.language = localStorage.getItem('lang');
        console.log(account);
        if(state !==null ){
          window.history.replaceState({},null);
        }
        fetch(config.url.BASE_URL+'user/register',{
          method:'POST',
          headers:{
              'Accept':'application/json',
              'Content-Type':'application/json'
          },
          mode: 'cors',
          body:JSON.stringify(account
          )
      })
      .then(res=>res.json())
      .then((result)=>{
         console.log(result);
          if(Array.isArray(result)){
            setActive(false);
              document.getElementById('AlertMessageRegister').innerHTML = intl.formatMessage({id: 'InvalidRegister'});
          }
          else{
              localStorage.setItem('access_token',result.token);
              localStorage.setItem('publicKeyCookie',result.publicKeyCookie);
              localStorage.setItem('privateKeyCookie',result.privateKeyCookie);
              localStorage.setItem('encryptionTestCookie',result.encryptionTestCookie);
              localStorage.setItem('roles',result.roles);
              localStorage.setItem('userId',result.id);
              if(result.culture===null){
                  localStorage.setItem('lang', "da");
              }else{
                  localStorage.setItem('lang', result.culture.slice(0, 2));
              }
              if(state !==null ){
                window.history.replaceState({},null);
                axios.post(`/Patient/Home/BookingConfirmation`,state.props)
                .then(
                (result) => {
                    navigate("/patient/bookingconfirmation", { state: { props: result.data } })
                }
                )
            }else{
                window.location.href="/dashboard";
            }
          }
      }
      )
      }
      function isChecked(){
        var btn = document.getElementById("RegisterButton");
        var chk = document.getElementById("Terms");
        if (chk.checked === true) {
            btn.disabled = false;
        } else {
            btn.disabled = true;
        };
      }
      function changeAddress(){
        setCAddress(document.getElementById('AddressId').value);
      }
      function serializeRSAKey(key) {
        return JSON.stringify({
            coeff: key.coeff.toString(16),
            d: key.d.toString(16),
            dmp1: key.dmp1.toString(16),
            dmq1: key.dmq1.toString(16),
            e: key.e.toString(16),
            n: key.n.toString(16),
            p: key.p.toString(16),
            q: key.q.toString(16)
        });
    }
    
    if(state !==null && cAddress===null){
      setCAddress(state.props.address);
    }

      const AutoComplete = () => {
        const autoCompleteRef = useRef();
        const inputRef = useRef();
        const options = {
          // componentRestrictions: { country: "dk" },
          types: ["geocode"]
          };
        useEffect(() => {
          try {
            setTimeout(() => {
            navigator.geolocation.getCurrentPosition(function(position) {
              const pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
              };
            let currentAddress;
            const google = window.google;
              var geocoder = new google.maps.Geocoder();
              let google_map_position = new google.maps.LatLng(pos.lat, pos.lng);
              geocoder.geocode(
                  { 'latLng': google_map_position },
                  function (results, status) {
                      currentAddress = results[0].formatted_address;
                      if(cAddress===null){
                        setCAddress(currentAddress);
                      }
                      account.googleAddress=cAddress;
                      document.getElementById('AddressId').value=cAddress;
                      
                  });
            });
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(
              inputRef.current,
              options
             );
             account.googleAddress=cAddress;
             document.getElementById('AddressId').value=cAddress;
            }, 500);
          } catch (exceptionVar) {
            console.log(exceptionVar);
          }
       
        }, []);
        return (
           <Form.Control  type="text" name="googleAddress" id='AddressId' autoComplete='off' ref={inputRef} onBlur={(e) => {changeAddress()}} className="row-Register" ></Form.Control>
        );
      };
      function changeDate(value,target){
        var date = document.getElementById('birthdayId').value;
        var datearray = [];
        var splitedChar="-";
        if(language==='en'){
          splitedChar=".";
          datearray = date.split(splitedChar);
        }else{
          datearray = date.split(splitedChar);
        }
        if(target === "day"){
          datearray[0]=value;
          var day=datearray[0];
          if(datearray[0]<10){
            day="0"+datearray[0];
          }
        }else if(target === "month"){
          datearray[1]=value;
        }else if(target === "year"){
          datearray[2]=value;
        }
        if(target === "day"){
          date = day+splitedChar+datearray[1]+splitedChar+datearray[2];
          setDay(datearray[0]);
        }else{
          date = datearray[0]+splitedChar+datearray[1]+splitedChar+datearray[2];
          if(datearray[0][0]==="0"){
            setDay(datearray[0][1]);
          }else{
            setDay(datearray[0]);
          }
        }
        setMonth(datearray[1]);
        setYear(datearray[2]);
        setDate(date);
      }

      useEffect(() => {
        const lang = localStorage.getItem('lang');
        let culture = "da-DK";
        if (lang === "en") {
          culture =  "en-GB";
        }
        fetch(config.url.BASE_URL+`Home/GetCountryList/`+culture)
          .then(response => response.json())
          .then(data => {
            setCitizenship(data);
            } 
            );
      }, []);

      const searchCitizenship = (e) => {
        countries=[];
        var counter=0;
        for(var i=0;i<e.length;i++){
          if(e[i].value===63){
            document.getElementById("CPRNummerDiv").classList.remove("d-none");
            counter++;
          }
          countries.push(e[i].value);
        }
        if(counter===0){
          document.getElementById("CPRNummerDiv").classList.add("d-none");
          document.getElementById('CPRNummerId').value="";
        }
        setSelectedCitizenship(e);
      }

  return (
    <div>
         <NewNavBar />
        <div className="pageD container">
            <div className="coverD">
                <h4 id="header"><FormattedMessage id="Register"/></h4>
                <Form className="register-form-style" onSubmit={registerPatient}>
             <h6 className="row-80"><FormattedMessage id="PersonalInformation"/></h6>
                 <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="FirstName"/></h6>
                     <Form.Control  type="text" name="firstName" id='firstNameId' autoComplete='off' className="row-Register-without-margins"  ></Form.Control>
                     <div className="alt-remember-register"><span id="firstNameIdError"></span></div>
                     </Form.Group>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="LastName"/></h6>
                     <Form.Control  type="text" name="lastName" id='lastNameId' autoComplete='off' className="row-Register-without-margins"   ></Form.Control>
                    <div className="alt-remember-register"><span id="lastNameIdError"></span></div>
                     </Form.Group>
                     <Form.Group>
                     <h6 className="row-80-h6"><FormattedMessage id="Email"/></h6>
                     <Form.Control  type="text" name="email" autoComplete='off' id='emailId' className="row-Register-without-margins"  ></Form.Control>
                     <div className="alt-remember-register"><span id="emailIdError"></span></div>
                     </Form.Group>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="ConfirmEmail"/></h6>
                     <Form.Control  type="text" name="confirmEmail" id='confirmEmailId' autoComplete='off' className="row-Register-without-margins"   ></Form.Control>
                     <div className="alt-remember-register"><span id="confirmEmailIdError"></span></div>
                     </Form.Group>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="Password"/></h6>
                     <Form.Control type="password" name="password" autoComplete='off' id='passwordId' className="row-Register-without-margins"   ></Form.Control>
                    <div className="alt-remember-register"><span id="passwordIdError"></span></div>
                     </Form.Group>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="ConfirmPassword"/></h6>
                     <Form.Control type="password" name="confirmPassword" id='confirmPasswordId' autoComplete='off' className="row-Register-without-margins"   ></Form.Control>
                     <div className="alt-remember-register"><span id="confirmPasswordIdError"></span></div>
                     </Form.Group>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="PhoneNumber"/></h6>
                     <Form.Control  type="text" name="phoneNumber" autoComplete='off' id="phoneNumberId" className="row-Register" ></Form.Control>
                     </Form.Group>
                     <Form.Group>
                     <h6 className="row-80-h6"><FormattedMessage id="Gender"/></h6>
                     <Form.Select className="row-Register" id='Gender'>
                        <option value="0"><FormattedMessage id="Male"/></option>
                        <option value="1"><FormattedMessage id="Female"/></option>
                        </Form.Select>
                     <Form.Group >
                    <h6 className="row-80-h6"><FormattedMessage id="Birthday"/></h6>
                    <div style={{display:"flex",marginBottom:"10px"}} className="form-group row-80-h6">
                          <div style={{width:"33%"}}>
                            <h6><FormattedMessage id="Day"/></h6>
                            <Form.Select id='Days' value={day} onChange={({ target: { value } }) => changeDate(value,"day")}>
                            {days.map((option) => (
                                <option  value={option}>
                                  {option}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                          <div style={{width:"33%"}}>
                          <h6><FormattedMessage id="Month"/></h6>
                            <Form.Select id='Months' value={month} onChange={({ target: { value } }) => changeDate(value,"month")}>
                            {months.map((option) => (
                                <option  value={option.key}>
                                  {option.value}
                                </option>
                              ))}
                               </Form.Select>
                          </div>
                          <div style={{width:"33%"}}>
                          <h6><FormattedMessage id="Year"/></h6>
                            <Form.Select id='Years' value={year} onChange={({ target: { value } }) => changeDate(value,"year")}>
                                {years.map((option) => (
                                <option  value={option}>
                                  {option}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                      </div>
                     <Form.Control  type="text" name="birthday" autoComplete='off' id="birthdayId" className="row-Register-without-margins" value={date} readOnly="true"></Form.Control>
                     <div className="alt-remember-register"><span id="birthdayIdError"></span></div>
                     </Form.Group>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="Address"/></h6>
                     <AutoComplete />
                     </Form.Group>

                     <Form.Group style={{marginBottom:"15px"}}>
                            <h6 className="row-80-h6"><FormattedMessage id="Citizenship"/></h6>
                            <Select className="row-80-h6" id='CitizenshipId'  options={citizenship} isMulti placeholder={<FormattedMessage id="SelectCitizenship"/>} value={selectedCitizenship} onChange={searchCitizenship}/>
                     </Form.Group>

                      <Form.Group className='d-none' id='CPRNummerDiv'>
                        <h6 className="row-80-h6"><FormattedMessage id="CPRNummer"/></h6>
                        <Form.Control  type="text"autoComplete='off' id="CPRNummerId" className="row-Register" onChange={fixCPRFormat}></Form.Control>
                        <div className="alt-remember-register"><span id="CPRNummerIdError"></span></div>
                     </Form.Group>

                     <div className="alt-Terms"><input type="checkbox" onChange={isChecked} value="Terms" id="Terms"/> <label for="Terms"><FormattedMessage id="AcceptTerms"/> <a target='_blank' href="/terms"><FormattedMessage id="Terms"/></a></label></div>
                     <br></br>
                     <Button type="submit" disabled='disabled' id='RegisterButton' className="btn btn-dark-blue row-80" value="Register"><FormattedMessage id="Register"/></Button>
                 </Form.Group>
             </Form>
             <div className="alt-remember"><label className="alert-message" id="AlertMessageRegister"></label>
             </div>
            </div>
            <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
        </div>
        {active && ( <Loading />)}
     </div>
    
  )
}

export default Register
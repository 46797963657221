import React from "react";
import {FormattedMessage} from 'react-intl';
import { FaMapMarkerAlt, FaEnvelope, FaMobileAlt } from "react-icons/fa";
function Footer() {

  return (
    <div id="FooterTile">
      <span> &copy; 2019 - Here2Treat.com ApS - CVR: 41247584 - <FaMapMarkerAlt style={{marginTop:"-5px"}} /> Voldgade 9, 2. 5700 Svendborg - <FaEnvelope style={{marginTop:"-2px"}}/> <a href="mailto:info@here2treat.com" id="footerEmail">info@here2treat.com</a> - <FaMobileAlt style={{marginTop:"-5px"}} />+45 29 72 81 30 - 
            <a target='_blank' href="/aboutus"> <FormattedMessage id="AboutUs"/></a></span><br /><div className="footer-payment-icon"></div><br /><div style={{marginBottom:"-30px"}} className="logo-da"></div><br /><a  class="logo-da-text" target="_blank" href="https://stps.dk/"><FormattedMessage id="DanishPatientSafetyAuthority"/></a>
    </div>
  )
}

export default Footer
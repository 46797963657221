let url = window.location.href;
let prod=[];
let liveURL=['https://here2treatyou.azurewebsites.net/','https://here2treat.com/','https://www.here2treat.com/'];
let demoUrlText= "demo";
let prodUrlText= "prod";
let devUrlText= "dev";
let live=false;
let demo=false;
liveURL.forEach(element => {
   if(url.includes(element) || url.includes(prodUrlText)){
        live=true;
        demo = false;
    }
    if(url.includes(demoUrlText)){
        live=false;
        demo = true;
    }
      if(url.includes(devUrlText)){
        live=false;
        demo = false;
    }
});

if(live){
    //Live
     prod = {
        url: {
            API_URL: 'https://here2treatapi.azurewebsites.net/api/',
            BASE_URL:'https://here2treatapi.azurewebsites.net/',
            WEB_URL:'https://doc.here2treat.com/'
        },
        enviroment: 'live'
       };
}else if (demo){
    //Dev
     prod = {
        url: {
            API_URL: 'https://demohere2treatapiwebapp.azurewebsites.net/api/',
            BASE_URL:'https://demohere2treatapiwebapp.azurewebsites.net/',
            WEB_URL:'https://demodoc.here2treat.com/'
        },
        enviroment: 'demo'
       };
}
else{
      prod = {
        url: {
            API_URL: 'https://here2treatapi-dev.azurewebsites.net/api/',
            BASE_URL:'https://here2treatapi-dev.azurewebsites.net/',
            WEB_URL:'https://here2testdoc.here2treat.com/'
        },
        enviroment: 'dev'
       }
}
 // Local
const dev = {
    url: {
        API_URL: 'https://localhost:7269/api/',
        BASE_URL:'https://localhost:7269/',
        //   API_URL: 'https://here2treatapi-dev.azurewebsites.net/api/',
        //   BASE_URL:'https://here2treatapi-dev.azurewebsites.net/',
          WEB_URL:'https://here2testdoc.here2treat.com/'
    },
     enviroment: demo ? 'demo' : 'dev'
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;
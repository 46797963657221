import axios from "axios";
import {config} from '../Constants';
const instance = axios.create({
  baseURL: config.url.BASE_URL,
});
instance.interceptors.request.use((request) => {
  if(localStorage.access_token !=null){
    request.headers.Authorization=`Bearer ${localStorage.access_token}`;
    return request;
  }else{
    window.location.href="/login";
  }
});

instance.interceptors.response.use((response) => {
  if(response.status===200){
    return response;
  }else if(response.status===401){
    console.log("Unauthorized");
    window.location.href="/login";
  }else{
    console.log(response.status);
    return response;
  }
  
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('publicKeyCookie');
    localStorage.removeItem('privateKeyCookie');
    localStorage.removeItem('encryptionTestCookie');
    localStorage.removeItem('roles');
    localStorage.removeItem('userId');
    window.location.href="/login";
  }
  console.log(error);
});

export default instance;
import React, { useState,useEffect } from "react";
import NewNavBar from "../navbar/new_navbar.js";
import {Button, Form} from 'react-bootstrap';
import axios from "../../context/axios_instance";
import {FormattedMessage,useIntl} from 'react-intl';
import Card from 'react-bootstrap/Card';
import Popup from "../popups/popup";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { FaTrashAlt,FaPen } from "react-icons/fa";
import Footer from "../footer/footer.js";
import Loading from "../loading/loading";
function ClosedPeriod() {
    const [enums, setEnums] = useState([]);
    const [closedPeriods, setClosedPeriods] = useState([]);
    const [active, setActive] = useState(false);
    const intl = useIntl();
    const togglePopup = () => {
        setIsOpen(!isOpen);
      }
    const [isOpen, setIsOpen] = useState(false);
    let [content, setContent] = useState(null); 
    let [closedPeriodId, setClosedPeriodId] = useState(null);  
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    useEffect(() => {
        GetEnums();
        GetClosedPeriods();
    }, [])

    function GetEnums(){
      axios.get('Doctor/Schedule/GetClosedPeriodType')
      .then(
        (result) => {
          setEnums(result.data);
        },
        (error) => {
        }
      )
    }

    let language = localStorage.getItem('lang');
    let locale="da-DK";
    let format="dd/MM/yyyy";
    if(language==='en'){
        locale="en-GB";
        format="dd.MM.yyyy";
    }

    function GetClosedPeriods(){
        axios.get('Doctor/Schedule/GetClosedPeriods')
        .then(
          (result) => {
            setClosedPeriods(result.data);
          },
          (error) => {
          }
        )
      }

    function openClosingPeriodForm(){
        document.getElementById("ClosedPeriodDiv").classList.remove("d-none");
    }

    function ValidateClosedPeriod(){
        var startDay = startDate.getDate();
        var startMonth = (startDate.getMonth()+1);
        var startYear = startDate.getFullYear();
        var endDay = endDate.getDate();
        var endMonth = (endDate.getMonth()+1);
        var endYear = endDate.getFullYear();
        //MM/dd/yyyy
        const model = {
            start: startMonth+"/"+startDay+"/"+startYear,
            end:endMonth+"/"+endDay+"/"+endYear
        }
        axios.post(`Doctor/Schedule/ValidateClosedPeriod`,model)
        .then(
          (result) => {
            if(result.data !=="No TimeSlot Found"){
                var availableTimeSlots = result.data.filter(a=>a.status===0).length;
                var bookedTimeSlots = result.data.filter(a=>a.status===1).length;
                if(availableTimeSlots>0 && bookedTimeSlots>0){
                    content = <div>
                    <div className="modal-header bg-dark-blue">
                        <h6 className="modal-title"><FormattedMessage id="ConfirmClosePeriod"/></h6>
                    </div>
                    <div className="modal-body text-center">
                    <div className="text-center">
                    <p class="alert alert-info">
                                                <b>{availableTimeSlots} <FormattedMessage id="Available Slots"/> </b>
                                                <FormattedMessage id="found"/> <FormattedMessage id="and"/>
                                                <br/> <b>{bookedTimeSlots} <FormattedMessage id="Booked Slots"/></b> <FormattedMessage id="found"/></p>
                                                <p class="alert alert-warning">
                                                <FormattedMessage id="WarningTS"/>
                      </p>
                    </div>
                    </div>
                    <div className="modal-footer">
                    <Button variant="success" style={{marginRight:"10px"}} onClick={(e) => ConfirmClosedPeriod()} size="sm"><FormattedMessage id="Confirm"/></Button>
                    <Button variant="danger" onClick={CancelClosedPeriod}  size="sm"><FormattedMessage id="Cancel"/></Button>
                    </div>
                </div>;
                setContent(content);
                setIsOpen(!isOpen);
                }
                else if(availableTimeSlots>0){
                    content = <div>
                    <div className="modal-header bg-dark-blue">
                        <h6 className="modal-title"><FormattedMessage id="ConfirmClosePeriod"/></h6>
                    </div>
                    <div className="modal-body text-center">
                    <div className="text-center">
                    <p class="alert alert-info">
                                                <b>{availableTimeSlots} <FormattedMessage id="Available Slots"/> </b>
                                                <FormattedMessage id="found"/></p>
                                                <p class="alert alert-warning">
                                                <FormattedMessage id="WarningTS"/>
                      </p>
                    </div>
                    </div>
                    <div className="modal-footer">
                    <Button variant="success" style={{marginRight:"10px"}} onClick={(e) => ConfirmClosedPeriod()} size="sm"><FormattedMessage id="Confirm"/></Button>
                    <Button variant="secondary" onClick={CancelClosedPeriod}  size="sm"><FormattedMessage id="Cancel"/></Button>
                    </div>
                </div>;
                setContent(content);
                setIsOpen(!isOpen);
                }else{
                    ConfirmClosedPeriod()
                }
            }
          }
        )
    }

    function ConfirmClosedPeriod(){
        setIsOpen(false);
        var startDay = startDate.getDate();
        var startMonth = (startDate.getMonth()+1);
        var startYear = startDate.getFullYear();
        var endDay = endDate.getDate();
        var endMonth = (endDate.getMonth()+1);
        var endYear = endDate.getFullYear();
        //MM/dd/yyyy
        const model = {
            start: startMonth+"/"+startDay+"/"+startYear,
            end:endMonth+"/"+endDay+"/"+endYear,
            description: document.getElementById("Description").value,
            type:parseInt(document.getElementById("Type").value)
        }
        if(closedPeriodId !=null){
            model.closedPeriodId=closedPeriodId;
        }
        axios.post(`Doctor/Schedule/SaveClosedPeriod`,model)
        .then(
          (result) => {
            GetClosedPeriods();
            ClearForm();
          });
        
    }

    function ClearForm(){
        setEndDate(new Date());
        setStartDate(new Date());
        document.getElementById("Description").value="";
        document.getElementById("Type").value=0;
    }

    function CancelClosedPeriod(){
        setIsOpen(false);
    }
    
    function editClosedPeriod(item){
        setClosedPeriodId(item.closedPeriodId);
        setEndDate(new Date(item.end));
        setStartDate(new Date(item.start));
        document.getElementById("Description").value=item.name;
        document.getElementById("Type").value=item.type;
        openClosingPeriodForm();
    }

    function deleteClosedPeriod(id){
        content = <div>
        <div className="modal-header bg-dark-blue">
            <h6 className="modal-title"><FormattedMessage id="DeleteClosePeriod"/></h6>
        </div>
        <div className="modal-body text-center">
        <div className="text-center">
        <p>           
           <FormattedMessage id="SureDelete"/>
          </p>
        </div>
        </div>
        <div className="modal-footer">
        <Button variant="danger" style={{marginRight:"10px"}} onClick={(e) => DeleteClosedPeriod(id)} size="sm"><FormattedMessage id="Delete"/></Button>
        <Button variant="secondary" onClick={CancelClosedPeriod}  size="sm"><FormattedMessage id="Cancel"/></Button>
        </div>
    </div>;
    setContent(content);
    setIsOpen(!isOpen);
    }

    function DeleteClosedPeriod(id){
        setIsOpen(false);
        axios.get('Doctor/Schedule/DeleteClosedPeriod/'+id)
        .then(
          (result) => {
            GetClosedPeriods();
          },
          (error) => {
          }
        )
    }

    function getAccordionItem(item){
            return <Card style={{color: "#007bff",marginBottom:"10px", width:"100%"}}>
            <Card.Header  as="h5" style={{background:"#e7f1ff"}}>{item.name}</Card.Header>
            <Card.Body>
              <Card.Text>
              <b><FormattedMessage id="Type"/>:</b> {intl.formatMessage({id: item.localizedType})}<br></br>
              <b><FormattedMessage id="Description"/>:</b> {item.name}<br></br>
              <b><FormattedMessage id="Period"/>:</b> {item.localizedPeriod}<br></br>
              </Card.Text>
              <Button variant="primary" style={{width:"100%",marginBottom:"5px"}} id={"edit"+item.closedPeriodId} onClick={(e) => editClosedPeriod(item)} size="m"><FormattedMessage  id="Edit"/><FaPen style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>
             <Button variant="danger" style={{width:"100%",marginBottom:"5px",color:"white"}} id={"delete"+item.closedPeriodId} onClick={(e) => deleteClosedPeriod(item.closedPeriodId)} size="m"><FormattedMessage  id="Delete"/><FaTrashAlt style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>
            </Card.Body>
            </Card>;
        }
    
    return (
        <div>
              <NewNavBar />
             <div className="pageD container">
                    <div className="coverD">
                        <div style={{width:"100%"}}>
                            <Button  variant="outline-secondary" style={{width:"100%"}} onClick={openClosingPeriodForm} className="btn btn-dark-blue"><FormattedMessage id="Add closing period"/></Button>
                        </div>
                        <div style={{width:"100%",marginTop:"20px"}} className="d-none" id="ClosedPeriodDiv">
                            <div style={{display:"flex",width:"100%"}}>
                                <b style={{width:"30%",marginTop:"5px"}}><FormattedMessage id="Type"/></b>
                                <p style={{width:"70%"}}><Form.Select id="Type">{enums.map(item => ( <option value={item.value}>{intl.formatMessage({id: item.text})}</option>))}</Form.Select></p>
                            </div>
                            <div style={{display:"flex",width:"100%"}}>
                                <b style={{width:"30%",marginTop:"5px"}}><FormattedMessage id="Description"/></b>
                                <p style={{width:"70%"}}><Form.Control type="text" id="Description"/></p>
                            </div>
                            <div style={{display:"flex",width:"100%"}}>
                                <b style={{width:"30%",marginTop:"5px"}}><FormattedMessage id="Start"/></b>
                                <DatePicker onChange={setStartDate} id="StartId" format={format} locale={locale} className="datepicker-Style" value={startDate} />
                            </div>
                            <div style={{display:"flex",width:"100%"}}>
                                <b style={{width:"30%",marginTop:"5px"}}><FormattedMessage id="End"/></b>
                                <DatePicker onChange={setEndDate}  format={format} locale={locale} className="datepicker-Style" value={endDate} />
                            </div>   
                              <Button  variant="outline-secondary" style={{width:"100%"}} onClick={ValidateClosedPeriod} className="btn btn-dark-blue"><FormattedMessage id="Save"/></Button>
                        </div>
                    </div>
                    <div className="coverD">
                    <h4 id="header"><FormattedMessage id="ClosedPeriod"/></h4>
                    {closedPeriods.map(item => (getAccordionItem(item)))}
                    </div>
                    <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
             </div>
             {isOpen && <Popup
            content={content}
            handleClose={togglePopup}
            />}
            {active && ( <Loading />)}
        </div>
      
      );
  }
  export default ClosedPeriod;
import React, { useEffect } from "react";
import NewNavBar from "../navbar/new_navbar.js";
import {FormattedMessage} from 'react-intl';
import Footer from "../footer/footer.js";
function AboutUs() {

  return (
    <div>
     <NewNavBar />
    <div className="pageD container">
           <div className="coverD">
                <h4><FormattedMessage id="AboutUs"/></h4>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="AboutUs11"/></li>
                            <li><FormattedMessage id="AboutUs12"/></li>
                            <li><FormattedMessage id="AboutUs13"/></li>
                            <li><FormattedMessage id="AboutUs14"/></li>
                        </ul> 
                 </div>
           </div>
           <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
    </div>
    </div>
  )
}

export default AboutUs
import React, { useEffect } from "react";
import NewNavBar from "../navbar/new_navbar.js";
import {FormattedMessage} from 'react-intl';
import Footer from "../footer/footer.js";
function Terms() {

  return (
    <div>
     <NewNavBar />
    <div className="pageD container">
           <div className="coverD">
                <h4><FormattedMessage id="TermsConditions"/></h4>
                <h6><FormattedMessage id="H1"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="R11"/></li>
                            <li><FormattedMessage id="R12"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H2"/></h6>
                 <h6><FormattedMessage id="HH1"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="RR1"/></li>
                            <li><FormattedMessage id="RR2"/></li>
                            <li><FormattedMessage id="RR3"/></li>
                            <li><FormattedMessage id="RR4"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="HH2"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="RR21"/></li>
                            <li><FormattedMessage id="RR22"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="HH3"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="RR31"/></li>
                            <li><FormattedMessage id="RR32"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="HH4"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="RR41"/></li>
                            <li><FormattedMessage id="RR42"/></li>
                            <li><FormattedMessage id="RR43"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H3"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="R31"/></li>
                            <li><FormattedMessage id="R32"/></li>
                            <li><FormattedMessage id="R33"/></li>
                            <li><FormattedMessage id="R34"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H4"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="R41"/></li>
                            <li><FormattedMessage id="R42"/></li>
                            <li><FormattedMessage id="R43"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H5"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="R51"/></li>
                            <li><FormattedMessage id="R52"/></li>
                            <li><FormattedMessage id="R52"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H6"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="R61"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H7"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="R71"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H8"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="R81"/></li>
                            <li><FormattedMessage id="R82"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H9"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="R91"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H10"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="R101"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H11"/></h6>
                <div style={{width:"100%"}}>
                        <ul>
                            <li><FormattedMessage id="R111"/></li>
                            <li><FormattedMessage id="R112"/></li>
                        </ul> 
                 </div>
                 <h6><FormattedMessage id="H12"/></h6>
           </div>
           <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
    </div>
    </div>
  )
}

export default Terms
import React , { useState ,useRef, useEffect} from 'react';
import NewNavBar from "../../navbar/new_navbar.js";
import {FormattedMessage,useIntl} from 'react-intl';
import {Button, Form} from 'react-bootstrap';
import * as Functions from '../../../functions/functions';
import axios from "../../../context/axios_instance";
import 'react-datepicker/dist/react-datepicker.css';
import * as alertify from 'alertifyjs';
import Select from 'react-select';
import 'alertifyjs/build/css/alertify.css';
import Popup from "../../popups/popup";
import Footer from '../../footer/footer.js';
import Loading from "../../loading/loading";
const account= {
        address:{},
        interestNames:[]
};
function DoctorProfile() {
  const intl = useIntl();
  let [content, setContent] = useState(null);  
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const [title, setTitle] = useState([]);
  const [active, setActive] = useState(true);
  const [bank, setBank] = useState([]);
  const [selected, setSelectInterests] = useState([]);
  const [interest, setInterest] = useState([]);
  alertify.set('notifier','position', 'top-right');

  const selectInterests = (e) => {
    account.interestNames=[];
    for(var i=0;i<e.length;i++){
      account.interestNames.push(e[i].value);
    }
    setSelectInterests(e);
  }

      useEffect(() => {
        axios.get('Doctor/Account/GetDoctorProfile')
        .then(
          (result) => {
            console.log(result.data);
            setInterest(result.data.selectInterests);
            setTitle(result.data.allDegreeTypes);
            setBank(result.data.allBanks);
            account.doctorId=result.data.doctorId;
            document.getElementById('NameId').innerHTML =result.data.fullName;
            document.getElementById('SpecialityNameLatinId').innerHTML =result.data.speciality.latinName ;
            document.getElementById('SpecialityNameId').innerHTML ="("+result.data.speciality.name +")";
            document.getElementById('AuthorizationId').innerHTML =intl.formatMessage({id: 'AuthorizationId'})+ " - "+result.data.doctorObject.authorizationId;
            document.getElementById('firstNameId').value=result.data.firstName;
            document.getElementById('lastNameId').value=result.data.lastName;
            document.getElementById('emailId').value=result.data.user.email;
            var interests=[]
            result.data.interests.forEach(element => {
              let sInterest ={};
              sInterest.value=element.interest.interestId;
              sInterest.label=element.interest.name;
              interests.push(sInterest);
              account.interestNames.push(element.interest.interestId);
            });
            setSelectInterests(interests);
            document.getElementById('SelectInterest').value=account.interestNames;
            var prefix=result.data.dialingCodesForCellPhone[0];
            result.data.dialingCodesForCellPhone.forEach(element => {
              if(element.selected){
                prefix=element.value;
              }
            });
            if(result.data.cellPhoneNumber===null ){
              document.getElementById('phoneNumberId').value=prefix.value;
            }else{
              document.getElementById('phoneNumberId').value=prefix+" "+result.data.cellPhoneNumber;
            }
            document.getElementById('Gender').value=result.data.gender;
            document.getElementById('birthdayId').value=Functions.fixDate(result.data.birthday.substr(0,10));
            document.getElementById('graduationId').value=Functions.fixDate(result.data.graduation.substr(0,10));
            document.getElementById('thesisId').value=Functions.fixDate(result.data.specialistFrom.substr(0,10));
            document.getElementById('WebsiteId').value=result.data.website;
            document.getElementById('ShortprofileId').value=result.data.description;
            document.getElementById('DetailedprofileId').value=result.data.detailedDescription;
            document.getElementById('WorkplaceId').value=result.data.workplace;
            document.getElementById('Title').value=result.data.title;
            document.getElementById('CompanyIdentificationNumberId').value=result.data.companyId;
            document.getElementById('CompanyNameId').value=result.data.companyName;
            if(result.data.clinicPhoneNumber===null){
              document.getElementById('ClinicPhonenumberId').value=prefix.value;
            }else{
              document.getElementById('ClinicPhonenumberId').value=prefix+" "+result.data.clinicPhoneNumber;
            }
            document.getElementById('RegistrationNumberId').value=result.data.registrationNumber;
            document.getElementById('AccountNumberId').value=result.data.accountNumber;
            setTimeout(function(){
              document.getElementById('BankId').value=result.data.bankId;
          }, 1000);
            document.getElementById('InvoiceReferenceId').value=result.data.invoiceReference;
            document.getElementById('Street1Id').value=result.data.address.street1;
            document.getElementById('Street2Id').value=result.data.address.street2;
            document.getElementById('ZipCodeId').value=result.data.address.zipCode;
            document.getElementById('CityId').value=result.data.address.city;
            document.getElementById('MunicipalityId').value=result.data.address.municipality;
            document.getElementById('StateId').value=result.data.address.state;
            if(result.data.address.country !==null){
              document.getElementById("CountryId").disabled = true;
            }
            if(result.data.bankId !==null){
              document.getElementById("BankId").disabled = true;
            }else{
               document.getElementById('bankDiv').style.display="none";
            }
            document.getElementById('CountryId').value=result.data.address.country;
            setActive(false);
          }
        )
      }, []);

      function ConfirmEmail() {
        document.getElementById('User_ConfirmEmailError').innerHTML="";
        document.getElementById('User_EmailError').innerHTML="";
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let emailConfirm = document.getElementById('User_ConfirmEmail').value;
        let email = document.getElementById('User_Email').value;
        if(email===""){
          document.getElementById('User_EmailError').innerHTML=intl.formatMessage({id: 'RequiredField'});
        }
        else if(!emailRegex.test(email)){
          document.getElementById('User_EmailError').innerHTML=intl.formatMessage({id: 'EmailNotValid'});
        }
        else if(emailConfirm===""){
          document.getElementById('User_ConfirmEmailError').innerHTML=intl.formatMessage({id: 'RequiredField'});
        }
        else if(email!==emailConfirm){
          document.getElementById('User_ConfirmEmailError').innerHTML=intl.formatMessage({id: 'EmailNotSame'});
        }
        else{
        editEmail();
        }
      }
      function submitCode(email){
        let code = document.getElementById('Code').value;
        if(code !==""){
              axios.get(`Doctor/Account/ConfirmEmailChange/${code}`)
                  .then(
                    (result) => {
                      if(result.status===200){
                        alertify.success(result.data);
                        document.getElementById('emailId').value=email;
                        document.getElementsByClassName("popup-box")[0].style.display="none";
                }else{
                  alertify.error("Failed");
                  document.getElementsByClassName("popup-box")[0].style.display="none";
                }
                }
            )
        }
   
      }

      function editEmail(){
        let email = document.getElementById('User_Email').value;
        axios.get(`Doctor/Account/SendChangeEmailConfirmation/${email}`)
          .then(
            (result) => {
              if(result.status===200){
                alertify.success(result.data);
                content = <div>
                <div className="modal-header bg-dark-blue">
                    <h6 className="modal-title"><FormattedMessage id="Update Email Address"/></h6>
                </div>
                <div className="modal-body text-center">
                <label className="control-label" style={{margin:"5px"}}><FormattedMessage id="Code"/></label>
                <input id="Code" type="text"  className="form-control" />
                <div className="alt-register-doctor"><span id="CodeError"></span></div>
                </div>
                <div className="modal-footer text-center" style={{display:"block"}}>
                <Button variant="secondary" size="sm" style={{marginRight:"20px"}}><FormattedMessage id="Cancel"/></Button>
                <Button variant="success" size="sm" onClick={(e) => submitCode(email)} ><FormattedMessage id="Submit"/></Button>
                </div>
            </div>;
            setContent(content);
            setTimeout(function(){
              document.getElementById('Code').value="";
          }, 1000);
              }else{
                alertify.error("Failed");
                setIsOpen(!isOpen);
              }
              }
          )
      }


      function editProfile(){
      if(document.getElementById('phoneNumberId').value !==""){
        account.cellPhoneNumber=document.getElementById('phoneNumberId').value;
        }
        if(document.getElementById('WebsiteId').value !==""){
          account.website=document.getElementById('WebsiteId').value;
        }
        if(document.getElementById('Gender').value !==""){
          account.gender=parseInt(document.getElementById('Gender').value);
        }
        if(document.getElementById('Title').value !==""){
          account.title=document.getElementById('Title').value;
        }
        if(document.getElementById('ShortprofileId').value !==""){
          account.description=document.getElementById('ShortprofileId').value;
        }
        if(document.getElementById('DetailedprofileId').value !==""){
          account.detailedDescription=document.getElementById('DetailedprofileId').value;
        }
        if(document.getElementById('WorkplaceId').value !==""){
          account.workplace=parseInt(document.getElementById('WorkplaceId').value);
        }
        if(document.getElementById('CompanyIdentificationNumberId').value !==""){
          account.companyId=parseInt(document.getElementById('CompanyIdentificationNumberId').value);
        }
        if(document.getElementById('CompanyNameId').value !==""){
          account.companyName=document.getElementById('CompanyNameId').value;
        }
        if(document.getElementById('ClinicPhonenumberId').value !==""){
          account.clinicPhoneNumber=document.getElementById('ClinicPhonenumberId').value;
        }
        if(document.getElementById('RegistrationNumberId').value !==""){
          account.registrationNumber=document.getElementById('RegistrationNumberId').value;
        }
        if(document.getElementById('AccountNumberId').value !==""){
          account.accountNumber=document.getElementById('AccountNumberId').value;
        }
        if(document.getElementById('InvoiceReferenceId').value !==""){
          account.invoiceReference=document.getElementById('InvoiceReferenceId').value;
        }
      account.address.street1=document.getElementById('Street1Id').value;
      account.address.street2=document.getElementById('Street2Id').value;
      account.address.municipality=document.getElementById('MunicipalityId').value;
      account.address.city=document.getElementById('CityId').value;
      account.address.country=document.getElementById('CountryId').value;
      account.address.zipCode=document.getElementById('ZipCodeId').value;
      account.address.state=document.getElementById('StateId').value;
        console.log(account);
        setActive(true);
        axios.post(`Doctor/Account/EditDoctor`, account)
        .then(
          (result) => {
            setActive(false);
            alertify.success(result.data);
          }
        )
       }

        function ChangeEmail(){
          content = <div>
                      <div className="modal-header bg-dark-blue">
                          <h6 className="modal-title"><FormattedMessage id="Update Email Address"/></h6>
                      </div>
                      <div className="modal-body text-center">
                      <label className="control-label" style={{margin:"5px"}}><FormattedMessage id="Email"/></label>
                      <input id="User_Email" type="text"  className="form-control" />
                      <div className="alt-register-doctor"><span id="User_EmailError"></span></div>
                      <label className="control-label" style={{margin:"5px"}}><FormattedMessage id="ConfirmEmail"/></label>
                      <input id="User_ConfirmEmail" type="text" className="form-control" />
                      <div className="alt-register-doctor"><span id="User_ConfirmEmailError"></span></div>
                      </div>
                      <div className="modal-footer text-center" style={{display:"block"}}>
                      <Button variant="secondary" size="sm" style={{marginRight:"20px"}}><FormattedMessage id="Cancel"/></Button>
                      <Button variant="success" size="sm" onClick={(e) => ConfirmEmail()}><FormattedMessage id="Send confirmation email"/></Button>
                      </div>
                  </div>;
          setContent(content);
          setIsOpen(!isOpen);
          setTimeout(function(){
            if(document.getElementById('User_Email') !==null){
              document.getElementById('User_Email').value=document.getElementById('emailId').value;
            }
        }, 1000);
        }

  return (
    <div style={{borderBottom: "inset"}}> 
         <NewNavBar />
          <Form >
          <div className="pageD container">
              <div className="coverD">
                <h4 id='NameId'></h4>
                <h6 id="SpecialityNameLatinId"></h6>
                <h6 id="SpecialityNameId"></h6>
                <h6 id="AuthorizationId"></h6>
              </div>
            </div>
            <div className="pageD container">
              <div className="coverD">
                <h4><FormattedMessage id="PersonalInformation"/></h4>
                <div className="register-form-style">
                 <Form.Group >
                     <h6><FormattedMessage id="FirstName"/></h6>
                     <Form.Control  type="text" name="firstName" disabled='disabled' id='firstNameId' autoComplete='off' className="row-register-doctor"></Form.Control>
                     <div className="alt-register-doctor"><span id="firstNameIdError"></span></div>
                     </Form.Group>

                     <Form.Group >
                     <h6><FormattedMessage id="LastName"/></h6>
                     <Form.Control  type="text" name="lastName" disabled='disabled' id='lastNameId' autoComplete='off' className="row-register-doctor" ></Form.Control>
                    <div className="alt-register-doctor"><span id="lastNameIdError"></span></div>
                     </Form.Group>

                    <div style={{background: "#8b8b8b3b", padding: "6px", borderRadius:"10px"}}> 
                      <Form.Group>
                     <h6><FormattedMessage id="Email"/></h6>
                     <Form.Control  type="text" name="email" disabled='disabled' autoComplete='off' id='emailId' className="row-register-doctor"  ></Form.Control>
                     <div className="alt-register-doctor"><span id="emailIdError"></span></div>
                     </Form.Group>
                     <Button type="button" className="btn btn-dark-blue row-80"  onClick={(e) => ChangeEmail()} ><FormattedMessage id="ChangeEmail"/></Button>
                     </div>


                     <Form.Group >
                     <h6><FormattedMessage id="PhoneNumber"/></h6>
                     <Form.Control  type="text" name="phoneNumber" id='phoneNumberId' autoComplete='off' className="row-Register-doctor"  ></Form.Control>
                     </Form.Group>

                     <h6><FormattedMessage id="Title"/></h6>
                     <Form.Select className="row-Register-doctor" id='Title'>
                     {title.map(item => (
                       <option value={item.value}>{item.text}</option>
                     ))}
                    </Form.Select>

                    <h6><FormattedMessage id="Gender"/></h6>
                    <Form.Select className="row-Register-doctor" id='Gender'>
                        <option value="0"><FormattedMessage id="Male"/></option>
                        <option value="1"><FormattedMessage id="Female"/></option>
                    </Form.Select>
                    <h6><FormattedMessage id="Birthday"/></h6>
                    <Form.Group >
                     <Form.Control  type="text" disabled='disabled' id='birthdayId' autoComplete='off' className="row-Register-doctor"  ></Form.Control>
                    </Form.Group>
                    <h6><FormattedMessage id="Graduation from Medical School"/></h6>
                    <Form.Group >
                     <Form.Control  type="text" disabled='disabled' id='graduationId' autoComplete='off' className="row-Register-doctor"  ></Form.Control>
                    </Form.Group>
                    <h6><FormattedMessage id="Thesis Obtained"/></h6>
                    <Form.Group >
                     <Form.Control  type="text" disabled='disabled' id='thesisId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
                    </Form.Group>
                    <h6><FormattedMessage id="Workplace"/></h6>
                    <Form.Select className="row-Register-doctor" id='WorkplaceId'>
                        <option value="0"><FormattedMessage id="OwnPrivateClinic"/></option>
                        <option value="1"><FormattedMessage id="PublicHospital"/></option>
                        <option value="2"><FormattedMessage id="PrivateHospital"/></option>
                        <option value="3"><FormattedMessage id="Other"/></option>
                    </Form.Select>
                    <h6><FormattedMessage id="Special Interests"/></h6>
                    <Select className="row-Select" id="SelectInterest" placeholder={<FormattedMessage id="SelectInterest"/>} options={interest} value={selected} onChange={selectInterests} isMulti />

                    <Form.Group >
                    <h6><FormattedMessage id="Website"/></h6>
                     <Form.Control  type="text"  id='WebsiteId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
                     </Form.Group>
                     <h6><FormattedMessage id="Short profile"/></h6>
                     <textarea className="row-Register-doctor-textarea" id='ShortprofileId' rows={5} />
                     <h6><FormattedMessage id="Detailed profile"/></h6>
                     <textarea className="row-Register-doctor-textarea" id='DetailedprofileId' rows={5} />
                  </div>
                  </div>
                 </div>
              <div className="pageD container">
              <div className="coverD">
                  <h4><FormattedMessage id="CompanyInformation"/></h4>
              
                    <h6><FormattedMessage id="CompanyIdentificationNumber"/></h6>
                     <Form.Control  type="number"  id='CompanyIdentificationNumberId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
              
                    <h6><FormattedMessage id="CompanyName"/></h6>
                     <Form.Control  type="text"  id='CompanyNameId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
             
                    <h6><FormattedMessage id="ClinicPhonenumber"/></h6>
                     <Form.Control  type="text"  id='ClinicPhonenumberId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
             
                    <h6><FormattedMessage id="RegistrationNumber"/></h6>
                     <Form.Control  type="text"  id='RegistrationNumberId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
             
                    <h6><FormattedMessage id="AccountNumber"/></h6>
                     <Form.Control  type="text"  id='AccountNumberId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
                    
                    
                  <div id='bankDiv' style={{textAlign: "-webkit-center"}}>
                    <h6><FormattedMessage id="Bank"/></h6>
                                <Form.Select className="row-Register-doctor" id='BankId'>
                                {bank.map(item => (
                            <option value={item.bankId} bank-code={"00"+item.bankCode}>{item.name} {item.notes}</option>
                          ))}
                          </Form.Select>
                    </div>
                   
              
                    <h6><FormattedMessage id="InvoiceReference"/></h6>
                     <Form.Control  type="text"  id='InvoiceReferenceId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
              
                    <h6><FormattedMessage id="Street1"/></h6>
                     <Form.Control  type="text"  id='Street1Id' autoComplete='off' className="row-Register-doctor" ></Form.Control>
              
                    <h6><FormattedMessage id="Street2"/></h6>
                     <Form.Control  type="text"  id='Street2Id' autoComplete='off' className="row-Register-doctor" ></Form.Control>
             
                    <h6><FormattedMessage id="ZipCode"/></h6>
                     <Form.Control  type="text"  id='ZipCodeId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
             
                    <h6><FormattedMessage id="City"/></h6>
                     <Form.Control  type="text"  id='CityId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
             
                    <h6><FormattedMessage id="Municipality"/></h6>
                     <Form.Control  type="text"  id='MunicipalityId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
              
                    <h6><FormattedMessage id="State"/></h6>
                     <Form.Control  type="text"  id='StateId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
              
                    <h6><FormattedMessage id="Country"/></h6>
                     <Form.Control  type="text"  id='CountryId' autoComplete='off' className="row-Register-doctor" ></Form.Control>
              
                  <Button type="button" className="btn btn-dark-blue row-80" onClick={editProfile}><FormattedMessage id="Edit"/></Button>
                  
              </div>
              <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
            </div>
             </Form>
             <div>
             {isOpen && <Popup
            content={content}
            handleClose={togglePopup}
            />}
        </div>
        {active && ( <Loading />)}
    </div>
  )
}

export default DoctorProfile
import React, { useEffect } from "react";
import {FormattedMessage} from 'react-intl';
import NewNavBar from "../navbar/new_navbar.js";
import Footer from "../footer/footer.js";
import { useLocation } from "react-router-dom";
import TargetText from "../targettext/target_text.js";
const Introduction = () => {
    const location = useLocation();
    return ( <div>
          <NewNavBar />
         <div className="pageD container">
         {/* <div className="coverD">
            <h4><FormattedMessage id="Instructions"/></h4>
            <h6><FormattedMessage id="InstructionsPatientHeading1"/></h6>
            <FormattedMessage id="InstructionsPatientBody1.1" /><br/><br/>
            <FormattedMessage id="InstructionsPatientBody1.2" /><br/><br/>
            <FormattedMessage id="InstructionsPatientBody1.3" />
        </div> */}
         <TargetText name= {location.pathname}/>
        <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
         </div>
        </div>)
}
export default Introduction;
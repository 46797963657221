import React, { useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import axios from "../../context/axios_instance";
import Button from 'react-bootstrap/Button';
import { FaTimes,FaThumbsUp,FaComment,FaInfoCircle,FaExclamationTriangle, FaLocationArrow} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import * as Functions from '../../functions/functions';
import Popup from "../popups/popup";
function DoctorBookingList() {
    let [content, setContent] = useState(null);  
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [encryptedData, setEncryptedData] = useState([]);
    const [patients, setPatients] = useState([]);
    const [culture, setCulture] = useState();
    const [origin, setOrigin] = useState(null);
    var arrayMonth = ['','JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    useEffect(() => {
        axios.get('Doctor/Home/DoctorDashboard')
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result.data.bookings);
            if(result.data.encryptedDataList){
              setEncryptedData(result.data.encryptedDataList);
            }
            setPatients(result.data.patients);
            setCulture(result.data.culture);
            let address = "";
            if(result.data.address.street1){
              address+=result.data.address.street1;
            } 
            if(result.data.address.street2){
              address+=" "+ result.data.address.street2;
            } 
            if(result.data.address.zipCode){
              address+=" "+ result.data.address.zipCode;
            } 
            if(result.data.address.city){
              address+=" "+ result.data.address.city;
            } 
            if(result.data.address.state){
              address+=" "+ result.data.address.state;
            } 
            if(result.data.address.country){
              address+=" "+ result.data.address.country;
            } 
            setOrigin(address);
          },
          (error) => {
            setIsLoaded(true);
          }
        )
    }, [])
  
    function getMonth(month){
        if(month<10){
            month = month.substring(1,2);
        }
        return arrayMonth[month];
    }
    function getPatient(id){
        var patient = patients.find(x=>x.patientId===id);
        var fullname = Functions.decryptString(patient.firstName) +" "+ Functions.decryptString(patient.lastName);
       return fullname;
    }

    function getIC(item){
        if(item.insuranceCompanyId!=null){
          return  <div><b><FormattedMessage id="InsuranceCompany"/>:</b> {item.insuranceCompany.name} <br></br> </div>;
      }
      else{
          return "";
      }
    }

    function getAddress(bookingId){
      var data = encryptedData.find(x=>x.objectId===bookingId);
      if(data){
        return  <div><b><FormattedMessage id="Address"/>:</b> {Functions.decryptString(data.value)} <br></br> </div>;
      }
      else{
          return "";
      }
  }

  function getRoute(bookingId){
    var data = encryptedData.find(x=>x.objectId===bookingId);
    if(data){
      return  <Button variant="dark" style={{width:"100%",marginBottom:"5px"}} size="m" onClick={(e) => openMap(data.value)}><FormattedMessage id="Directions"/><FaLocationArrow /></Button>;
    }
    else{
        return "";
    }
}

function openMap(address) {
  if(address != null){
      address = Functions.decryptString(address);
      window.open(`https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(address)}&travelmode=driving`, "_blank");
  }
}

    function getColor(item){
      if(item.status === 8 || item.status === 2 || item.status === 7){
         return "#dc3545";
      }else if(item.status === 4){
          return "rgb(6 185 11)";
      }
      else if(item.status === 5){
          return "#0d793f";
      }
      else{
          return "#007bff";
      }
  }
    function confirmBookingPopup(fname, lname, email, bday, bookingId){
        content = <div>
                    <div className="modal-header bg-dark-blue">
                        <h6 className="modal-title"><FormattedMessage id="ConfirmBooking"/></h6>
                    </div>
                    <div className="modal-body text-center">
                        <b style={{color: "#0c4a7c"}}>
                        <FormattedMessage id="ConfirmMessage"/>
                        </b>
                    </div>
                    <div className="modal-footer">
                    <Button variant="success" id="ConfirmBooking" onClick={(e) => confirmBooking(fname, lname, email, bday, bookingId)}  size="sm"><FormattedMessage id="Confirm"/></Button>
                    </div>
                </div>;
        setContent(content);
        setIsOpen(!isOpen);
    }
    function confirmBooking(fname, lname, email, bday, bookingId){
        setIsOpen(false);
        axios.get(`Home/ConfirmBooking/${bookingId}/${email}/${fname}/${lname}/${bday}`)
        .then(
          (result) => {
            document.getElementById("confirm" + bookingId).style.display="none";
          }
        )
    }
    function cancelBooking(bookingId){
        var reason=document.getElementById("ReasonId").value;
        if(reason !==""){
          setIsOpen(false);
          document.getElementById(bookingId).style.display="none";
          axios.get(`Home/CancelBooking/${bookingId}/${reason}`)
          .then(
            (result) => {
              document.getElementById("cancel" + bookingId).style.display="none";
              if(document.getElementById("confirm" + bookingId)!==null){
                document.getElementById("confirm" + bookingId).style.display="none";
              }
            }
          )
        }else{
          document.getElementById(bookingId).style.display="block";
        }
       
    }
    function cancelBookingPopup(bookingId){
        content = <div>
                    <div className="modal-header bg-dark-blue">
                        <h6 className="modal-title"><FormattedMessage id="CancelBooking"/></h6>
                    </div>
                    <div className="modal-body text-center">
                    <div className="text-center">
                    <p><FormattedMessage id="CancelReason"/></p>
                     </div>
                     <label className="control-label"><FormattedMessage id="Reason"/></label>
                    <input id="ReasonId" type="text" className="form-control" />
                    <p style={{color:"red",display:"none"}} id={bookingId}><FormattedMessage id="RequiredField"/></p>
                    <br></br>
                        <b style={{color: "#0c4a7c"}}>
                        <FormattedMessage id="PressCancel"/>
                        </b>
                    </div>
                    <div className="modal-footer">
                    <Button variant="danger" onClick={(e) => cancelBooking(bookingId)}  size="sm"><FormattedMessage id="Cancel"/></Button>
                    </div>
                </div>;
         setContent(content);
        setIsOpen(!isOpen);
    }

    function GetNoBookingDiv(){
      if(items.length===0){
          return <div style={{marginLeft:"10px"}}><FormattedMessage id="NoBooking"/></div>;
      }
    }

    function openChat(bookingId,name){
      navigate("/chat", { state: {  id: bookingId, name: name } });
  }

  function openDetails(patientModel,details,services){

    navigate("/details", { state: {  patient: patientModel, details: details, services: services, currency: Functions.getCurrency(culture) } });
}

  function getButtons(item){
    var status = item.status;
    var patientId = item.patientId;
    var bookingId = item.bookingId;
    var notification ="";
    if(item.unreadMessagesCount !==0){
      notification = <span className="chat-notification" style={{marginLeft:"-12px"}}>{item.unreadMessagesCount}</span> ;
    }
    var patient = patients.find(x=>x.patientId===patientId);
    var fname = Functions.decryptString(patient.firstName);
    var lname = Functions.decryptString(patient.lastName);
    var email = Functions.decryptString(patient.email);
    var x = Functions.decryptString(patient.birthday);
    var bdaymil = parseInt(Functions.decryptString(patient.birthday));
    var birthDate=new Date(bdaymil);
    var bday = birthDate.toDateString();
     let patientModel={
      fullName:fname+" "+lname,
      email:email,
      bday:birthDate.getDate()+"-"+(birthDate.getMonth()+1)+"-"+birthDate.getFullYear(),
      phoneNumber:Functions.decryptString(patient.phoneNumber)
     }

        switch (status) {
        case 3:
            return <div  style={{width:"100%",marginTop:"5px"}}><Button variant="success" style={{width:"100%",marginBottom:"5px"}} id={"confirm"+bookingId} onClick={(e) => confirmBookingPopup(fname, lname, email, bday, bookingId)} size="m"><FormattedMessage  id="Confirm"/><FaThumbsUp style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>
             <Button variant="info" style={{width:"100%",marginBottom:"5px",color:"white"}} id={"info"+bookingId} onClick={(e) => openDetails(patientModel,item.details,item.services)} size="m"><FormattedMessage  id="Details"/><FaInfoCircle style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>
             <Button variant="primary" style={{width:"100%",marginBottom:"5px"}} data-chat={"chat_"+item.bookingId} onClick={(e) => openChat(item.bookingId,getPatient(item.patientId))}><FormattedMessage id="Chat"/><FaComment style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>{notification}
             {getRoute(bookingId)}
             <Button variant="danger" style={{width:"100%",marginBottom:"5px",marginTop:"25px"}} id={"cancel"+bookingId} onClick={(e) => cancelBookingPopup(bookingId)} size="m"><FormattedMessage  id="Cancel"/><FaTimes style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>
             </div>;
        case 1:
            return <div  style={{width:"100%",marginTop:"5px"}}>
             <Button variant="info" style={{width:"100%",marginBottom:"5px",color:"white"}} id={"info"+bookingId} onClick={(e) => openDetails(patientModel,item.details,item.services)} size="m"><FormattedMessage  id="Details"/><FaInfoCircle style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>
            <Button variant="primary" style={{width:"100%",marginBottom:"5px"}} size="m" data-chat={"chat_"+item.bookingId} onClick={(e) => openChat(item.bookingId,getPatient(item.patientId))}><FormattedMessage id="Chat"/><FaComment style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>{notification}
            {getRoute(bookingId)}
            <Button variant="danger" style={{width:"100%",marginBottom:"5px",marginTop:"25px"}} id={"cancel"+bookingId} onClick={(e) => cancelBookingPopup(bookingId)} size="m"><FormattedMessage id="Cancel"/><FaTimes style={{marginLeft:"5px",marginTop:"-4px"}}/></Button>
            </div>;
     default:
            break;
        }
    
}
function getUncomfirmedIcon(item){
  if(item.status===3){
    return <FaExclamationTriangle style={{marginLeft:"-10px",marginRight:"5px",marginTop:"-2px"}}/>;
  }
}


    if (!isLoaded) {
      return <div><FormattedMessage id="Loading"/></div>;
    } else {
      return (
        <div>
          {GetNoBookingDiv()}
              <Accordion>
          {items.map(item => (
            <Accordion.Item eventKey={item.bookingId}>
            <Accordion.Header id={"confirmbookingbtn_"+item.bookingId}><div style={{color: getColor(item)}}>{getUncomfirmedIcon(item)}{item.start.substring(8, 10)} {getMonth(item.start.substring(5,7))} - {getPatient(item.patientId)}</div></Accordion.Header>
            <Accordion.Body style={{color: getColor(item)}}>
             <b><FormattedMessage id="Time"/>:</b> {item.start.substring(11,16)} - {item.end.substring(11,16)}<br></br>
             {getIC(item)}
             {getAddress(item.bookingId)}
             <b><FormattedMessage id="Title"/>:</b> {item.title}<br></br>
             <b><FormattedMessage id="Price"/>:</b> {item.doctorPrice} {Functions.getCurrency(culture)}<br></br>
            {getButtons(item)}
            </Accordion.Body>
        </Accordion.Item>
          ))}
        </Accordion>
        {isOpen && <Popup
            content={content}
            handleClose={togglePopup}
            />}
        </div>
      
      );
    }
  }
  export default DoctorBookingList;
import React, { useEffect, useState } from "react";
import NewNavBar from "../navbar/new_navbar.js";
import {FormattedMessage,useIntl} from 'react-intl';
import Footer from "../footer/footer.js";
import axios from "../../context/axios_instance";
import Popup from "../popups/popup";
import * as alertify from 'alertifyjs';
import {Button, Form} from 'react-bootstrap';
function Security() {
    let [content, setContent] = useState(null);  
    const [isOpen, setIsOpen] = useState(false);
    alertify.set('notifier','position', 'top-right');
    const intl = useIntl();
    const togglePopup = () => {
        setIsOpen(!isOpen);
      }
    const DownloadKeys = () => {
        var password=document.getElementById("PasswordId").value;
        if(password !==""){
          document.getElementById("IncorrectPasswordId").style.display="none";
          axios.get(`Home/DownloadKeys/${password}`)
          .then(
            (result) => {
                if(result.data==""){
                    document.getElementById("IncorrectPasswordId").style.display="block";
                }else{
                    console.log(result);
                    const content = result.data;
                    const blob = new Blob([content], { type: 'text/plain' });
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = 'Here2Treat Keys.txt';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    setIsOpen(false);
                }
            }
          )
        }else{
          document.getElementById("IncorrectPasswordId").style.display="block";
        }
    }

    const UploadKeys = () => {
        var password=document.getElementById("PasswordId").value;
        var keys=document.getElementById("KeysId").value;
        document.getElementById("IncorrectPasswordId").style.display="none";
        document.getElementById("KeysErrorId").style.display="none";
        if(password ===""){
          document.getElementById("IncorrectPasswordId").style.display="block";
        }else if(keys===""){
          document.getElementById("KeysErrorId").style.display="block";
        }else{
            const formData = new FormData();
            formData.append('file', document.getElementById("KeysId").files[0]);
            formData.append('password', password);
    
            axios.post(`/Home/UploadKeys`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(response => {
                if(response.data.succeeded){
                    alertify.success(intl.formatMessage({id: "KeysUploadedSuccess"}));
                    setIsOpen(false);
                }else{
                    alertify.error(intl.formatMessage({id: response.data.errors[0].description}));
                }
            })
            .catch(error => {
                console.error('Error uploading:', error);
                alertify.error(intl.formatMessage({id: "KeysUploadedError"}));
                setIsOpen(false);
            });
        }
    }

    function DownloadKeysPopup(){
        content = <div>
                    <div className="modal-header bg-dark-blue" style={{padding:"3px"}}>
                        <h6 className="modal-title"><FormattedMessage id="DownloadKeys"/></h6>
                    </div>
                    <div className="modal-body text-center">
                     <label className="control-label"><FormattedMessage id="Password"/></label>
                    <input id="PasswordId" type="password" className="form-control" />
                    <p id="IncorrectPasswordId" style={{color:"red",display:"none"}}><FormattedMessage id="IncorrectPassword"/>!</p>
                    </div>
                    <div className="modal-footer">
                    <div className="btn btn-dark-blue" style={{padding:"3px 13px"}} onClick={DownloadKeys} ><FormattedMessage id="Download"/> </div>
                    </div>
                </div>;
         setContent(content);
        setIsOpen(!isOpen);
    }

    function UploadKeysPopup(){
        content = <div>
                    <div className="modal-header bg-dark-blue" style={{padding:"3px"}}>
                        <h6 className="modal-title"><FormattedMessage id="UploadKeys"/></h6>
                    </div>
                    <div className="modal-body text-center">
                     <label className="control-label"><FormattedMessage id="Password"/></label>
                    <input id="PasswordId" type="password" className="form-control" />
                    <p id="IncorrectPasswordId" style={{color:"red",display:"none"}}><FormattedMessage id="RequiredField"/></p>
                    <label className="control-label" style={{marginTop:"1rem"}}><FormattedMessage id="Keys"/></label>
                    <input id="KeysId" type="file" className="form-control" />
                    <p id="KeysErrorId" style={{color:"red",display:"none"}}><FormattedMessage id="RequiredField"/></p>
                    </div>
                    <div className="modal-footer">
                    <div className="btn btn-dark-blue" style={{padding:"3px 13px"}} onClick={UploadKeys} ><FormattedMessage id="Upload"/> </div>
                    </div>
                </div>;
         setContent(content);
        setIsOpen(!isOpen);
    }

    const updatePassword = (e) => {
        e.preventDefault();
        document.getElementById('passwordIdError').innerHTML="";
        document.getElementById('newPasswordIdError').innerHTML="";
        document.getElementById('confirmNewPasswordIdError').innerHTML="";
        let passwordRegex =  new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
        let password = document.getElementById('passwordId').value;
        let newPassword = document.getElementById('newPasswordId').value;
        let cNewPassword = document.getElementById('confirmNewPasswordId').value;
          if(password===""){
            document.getElementById('passwordIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
          }
          else if(newPassword===""){
            document.getElementById('newPasswordIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
          }
          else if(!passwordRegex.test(newPassword)){
            document.getElementById('newPasswordIdError').innerHTML=intl.formatMessage({id: 'PasswordRegex'});
         }
            else if(newPassword!==cNewPassword){
            document.getElementById('confirmNewPasswordIdError').innerHTML=intl.formatMessage({id: 'PasswordNotMatch'});
          }else{

            let model={
                oldPassword:password,
                newPassword:newPassword,
                confirmPassword:cNewPassword,
                publicKey:localStorage.getItem('publicKeyCookie'),
                privateKey:localStorage.getItem('privateKeyCookie')
            };
            axios.post(`/Home/ChangePassword`,model)
                .then(
                (result) => {
                    document.getElementById('passwordId').value="";
                    document.getElementById('newPasswordId').value="";
                    document.getElementById('confirmNewPasswordId').value="";
                    if(result.data=="Success"){
                        alertify.success(intl.formatMessage({id: "PasswordChangedSuccess"}));
                    }else{
                        alertify.error(intl.formatMessage({id: "PasswordChangedError"}));
                    }
                }
                )
          }
    }

  return (
    <div>
     <NewNavBar />
    <div className="pageD container">
           <div className="coverD">
                <h4><FormattedMessage id="ProfileSettings"/></h4>
           </div>
           <div className="coverD">
                <h5><FormattedMessage id="DownloadKeys"/></h5>
                <div className="register-form-style" style={{width:"80%"}}>
                    <p><FormattedMessage id="DownloadKeys1"/></p>
                    <p className="alert-warning" style={{padding:"13px"}}><FormattedMessage id="DownloadKeys2"/></p>
                    <div className="btn btn-dark-blue row-80" style={{width:"100%"}}  onClick={DownloadKeysPopup} ><FormattedMessage id="Download"/> </div>
                </div>
           </div>
           <div className="coverD d-none">
                <h5><FormattedMessage id="UploadKeys"/></h5>
                <div className="register-form-style" style={{width:"80%"}}>
                    <p><FormattedMessage id="UploadKeys1"/></p>
                    <p className="alert-warning" style={{padding:"13px"}}><FormattedMessage id="UploadKeys2"/></p>
                    <p className="alert-danger" style={{padding:"13px"}}><FormattedMessage id="UploadKeys3"/></p>
                    <div className="btn btn-dark-blue row-80" style={{width:"100%"}}  onClick={UploadKeysPopup} ><FormattedMessage id="Upload"/> </div>
                </div>
           </div>
           <div className="coverD">
                <h5><FormattedMessage id="ChangePassword"/></h5>
                <Form className="register-form-style" onSubmit={updatePassword}>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="CurrentPassword"/></h6>
                     <Form.Control  type="password"  id='passwordId' autoComplete='off' className="row-Register-without-margins"   ></Form.Control>
                     <div className="alt-remember-register"><span id="passwordIdError"></span></div>
                     </Form.Group>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="NewPassword"/></h6>
                     <Form.Control type="password"  autoComplete='off' id='newPasswordId' className="row-Register-without-margins"   ></Form.Control>
                    <div className="alt-remember-register"><span id="newPasswordIdError"></span></div>
                     </Form.Group>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="ConfirmNewPassword"/></h6>
                     <Form.Control type="password"  id='confirmNewPasswordId' autoComplete='off' className="row-Register-without-margins"   ></Form.Control>
                     <div className="alt-remember-register"><span id="confirmNewPasswordIdError"></span></div>
                     </Form.Group>
                   
                     <Form.Group >
                     <Button type="submit" id='UpdatePasswordButton' className="btn btn-dark-blue row-80" value="UpdatePassword"><FormattedMessage id="UpdatePassword"/></Button>
                    </Form.Group>
             </Form>
           </div>
           <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
    </div>
    {isOpen && <Popup
            content={content}
            handleClose={togglePopup}
            />}
    </div>
  )
}

export default Security
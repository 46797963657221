import React, { useState } from "react";
import {Button, Form} from 'react-bootstrap';
import {FormattedMessage,useIntl} from 'react-intl';
import * as Functions from '../../functions/functions';
import {config} from '../../Constants';
import axios from "../../context/axios_instance";
import { useLocation,useNavigate  } from "react-router-dom";
import Footer from "../footer/footer.js";
import NewNavBar from "../navbar/new_navbar.js";
import Roles from '../../roles';
import Loading from "../loading/loading";
const Login = () => {
    const intl = useIntl();
    const {state} = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState(false);
    const [account, setAccount] = useState({
        username: '',
        password: ''
      });
      let isMobile=false;
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
           || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
              isMobile=true;
       }
      const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        account[name] = value;
        setAccount(account);
      }
      function submitLogin(){
        setActive(true);
        document.getElementById('AlertMessage').innerHTML = '';
                fetch(config.url.API_URL+'user/login',{
                method:'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(account
                )
            })
            .then(res=>res.json())
            .then((result)=>{
                setActive(false);
                if(result==="Credentials incorrect"){
                   
                    document.getElementById('AlertMessage').innerHTML = intl.formatMessage({id: 'Credentialsincorrect'});
                }else if(Array.isArray(result)){
                    document.getElementById('AlertMessage').innerHTML = intl.formatMessage({id: 'InvalidLogin'});
                }
                else if(result.roles.includes(Roles.Doctor) && !isMobile){
                    document.getElementById('AlertMessage').innerHTML = intl.formatMessage({id: 'LoginMessageForDoctors1'}) + `<a href="${config.url.WEB_URL}">${intl.formatMessage({id: 'DoctorPortal'})}</a>` + intl.formatMessage({id: 'LoginMessageForDoctors2'});
                }
                else if(result.roles.includes(Roles.Doctor) && isMobile){
                    document.getElementById('AlertMessage').innerHTML = intl.formatMessage({id: 'LoginMessageForDoctors1'}) + `<a href="/doctorlogin">${intl.formatMessage({id: 'DoctorPortal'})}</a>` + intl.formatMessage({id: 'LoginMessageForDoctors2'});
                }
                else{
                    localStorage.setItem('access_token',result.token);
                    localStorage.setItem('publicKeyCookie',result.publicKeyCookie);
                    localStorage.setItem('privateKeyCookie',result.privateKeyCookie);
                    localStorage.setItem('encryptionTestCookie',result.encryptionTestCookie);
                    localStorage.setItem('roles',result.roles);
                    localStorage.setItem('userId',result.id);
                    if(result.culture===null){
                        localStorage.setItem('lang', "da");
                    }else{
                        localStorage.setItem('lang', result.culture.slice(0, 2));
                    }
                    if(state !==null ){
                        window.history.replaceState({},null);
                        axios.post(`/Patient/Home/BookingConfirmation`,state.props)
                        .then(
                        (result) => {
                            navigate("/patient/bookingconfirmation", { state: { props: result.data, patientAddress: state.patientAddress } })
                        }
                        )
                    }else{
                        window.location.href="/dashboard";
                    }
                }
            }
            )
      }

      function PatientRegister(){
        if (state !==null )
        {
            window.history.replaceState({},null);
            navigate("/patient/register", { state: { props: state.props } });
        }else{
            window.location.href="/patient/register";
        }
      }
      function GetCreatePatientButtonMobile(){
        if(isMobile===true){
            return <a className="btn btn-dark-blue row-80" id="CreatePatientProfileButton"  onClick={PatientRegister} ><FormattedMessage id="NewPatient"/></a>;
        } 
      }
      function GetCreateLoginPatientButtonWeb(){
        if(isMobile===false){
            return  <div className="alt-remember"  style={{justifyContent:"space-between"}}>
            <Button type="button" id="PerformPatientLoginButton" onClick={submitLogin}  style={{width:"49%"}} className="btn btn-dark-blue row-80 float-left" value="Login"><FormattedMessage id="LoginAsPatient"/></Button><a className="btn btn-dark-blue row-80" style={{width:"49%"}} id="CreatePatientProfileButton"  onClick={PatientRegister} ><FormattedMessage id="NewPatient"/></a>
            </div>;
        }else{
            return  <Button type="button" id="PerformPatientLoginButton" onClick={submitLogin} className="btn btn-dark-blue row-80" value="Login"><FormattedMessage id="LoginAsPatient"/></Button>;
        } 
      }

    return (
            <div>
                <NewNavBar />
            <div className="page container">
                    <div className="cover">
                        <Form className="align-center">
                        <h1 className="row-80"><FormattedMessage id="PatientLogin"/></h1>
                        <h6 style={{marginBottom :"20px"}}><FormattedMessage id="LoginInfo"/><br></br><FormattedMessage id="LoginInfo1"/></h6>
                            <Form.Group controlId="Username">
                                <Form.Control  type="text" name="username" className="row" placeholder={Functions.useFormatMessage('Username')} onChange={handleChange} ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId="Password">
                                <Form.Control type="password" name="password" className="row" placeholder={Functions.useFormatMessage('Password')} onChange={handleChange} ></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                {GetCreateLoginPatientButtonWeb()}
                                <div className="alt-remember"><label className="alert-message" id="AlertMessage"></label></div>
                        {/* <div className="logo"></div> */}
                        {GetCreatePatientButtonMobile()}
                        <div className="remember-forgot" style={{marginBottom:"10px"}}>
                        <input type="checkbox" style={{marginTop:"-10px",marginRight:"10px"}} value="lsRememberMe" id="rememberMe"/> <label for="rememberMe" style={{marginRight:"10px"}}><FormattedMessage id="Rememberme"/></label><a  href="/forgotpassword"><FormattedMessage id="Forgotpassword"/></a>
                        </div>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="cover">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
            </div>
            {active && ( <Loading />)}
            </div>
                )
  };
  export default Login;
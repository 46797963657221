import React, { useEffect, useState } from "react";
import NewNavBar from "../navbar/new_navbar.js";
import {FormattedMessage,useIntl} from 'react-intl';
import Footer from "../footer/footer.js";
import Loading from "../loading/loading";
import * as alertify from 'alertifyjs';
import {config} from '../../Constants';
import {Button, Form} from 'react-bootstrap';
function ForgotPassword() {
    alertify.set('notifier','position', 'top-right');
    const intl = useIntl();
    const [active, setActive] = useState(false);
    const sendValidationToken = () => {
        document.getElementById('emailIdError').innerHTML="";
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var email=document.getElementById("emailId").value;
        if(email==="")
        {
            document.getElementById('emailIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
        }
        else if(!emailRegex.test(email))
        {
            document.getElementById('emailIdError').innerHTML=intl.formatMessage({id: 'EmailNotValid'});
        }
        else
        {
            setActive(true);
            fetch(config.url.BASE_URL+'Home/SendValidationToken',{
                method:'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(email)
                })
                .then(res=>res.json())
                .then((result)=>{
                    if(result){
                        alertify.success(intl.formatMessage({id: "TokenSentSuccessfully"}));
                        document.getElementById("resetPasswordDiv").classList.remove("d-none");
                    }else{
                        alertify.error(intl.formatMessage({id: "ErrorSendingToken"}));
                    }
                    setActive(false);
                }
            )
        }
    }

    const resetPassword = (e) => {
        e.preventDefault();
        document.getElementById('validationTokenIdError').innerHTML="";
        document.getElementById('keyFileIdError').innerHTML="";
        document.getElementById('newPasswordIdError').innerHTML="";
        document.getElementById('confirmNewPasswordIdError').innerHTML="";
        let passwordRegex =  new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
        let token = document.getElementById('validationTokenId').value;
        let newPassword = document.getElementById('newPasswordId').value;
        var keys=document.getElementById("keyFileId").value;
        var email=document.getElementById("emailId").value;
        let cNewPassword = document.getElementById('confirmNewPasswordId').value;
          if(token==="")
          {
            document.getElementById('validationTokenIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
          }
          else if(newPassword==="")
          {
            document.getElementById('newPasswordIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
          }
          else if(!passwordRegex.test(newPassword))
          {
            document.getElementById('newPasswordIdError').innerHTML=intl.formatMessage({id: 'PasswordRegex'});
          }
          else if(newPassword!==cNewPassword)
          {
            document.getElementById('confirmNewPasswordIdError').innerHTML=intl.formatMessage({id: 'PasswordNotMatch'});
          }
          else if(keys==="")
          {
            document.getElementById('keyFileIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
          }
          else
          {
            setActive(true);
            const formData = new FormData();
            formData.append('file', document.getElementById("keyFileId").files[0]);
            formData.append('newPassword', newPassword);
            formData.append('token', token);
            formData.append('email', email);

            fetch(config.url.BASE_URL+'Home/ResetPassword',{
                method:'POST',
                body:formData
                })
                .then(res=>res.json())
                .then((result)=>{
                    if(result){
                        alertify.success(intl.formatMessage({id: "ResetPasswordSuccess"}));
                        window.location.href="/login";
                    }
                    setActive(false);
                }
                    
                )
                .catch(error => {
                    console.error('Error:', error);
                    alertify.error(intl.formatMessage({id: "ResetPasswordError"}));
                    setActive(false);
                });
          }
    }

  return (
    <div>
     <NewNavBar />
    <div className="pageD container">
           <div className="coverD">
                <h5><FormattedMessage id="ResetPassword"/></h5>
                <div className="register-form-style" >
                    <p><FormattedMessage id="ResetPasswordTitle"/></p>
                    <h6 className="row-80-h6"><FormattedMessage id="Email"/></h6>
                     <Form.Control  type="text"  id='emailId' autoComplete='off' className="row-Register-without-margins"   ></Form.Control>
                     <div className="alt-remember-register"><span id="emailIdError"></span></div>
                    <div className="btn btn-dark-blue row-80" style={{width:"80%"}}  onClick={sendValidationToken} ><FormattedMessage id="Next"/> </div>
                </div>
           </div>
           <div id="resetPasswordDiv" className="coverD d-none">
                <Form className="register-form-style" onSubmit={resetPassword}>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="ValidationToken"/></h6>
                     <Form.Control  type="text"  id='validationTokenId' autoComplete='off' className="row-Register-without-margins"   ></Form.Control>
                     <div className="alt-remember-register"><span id="validationTokenIdError"></span></div>
                     </Form.Group>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="NewPassword"/></h6>
                     <Form.Control type="password"  autoComplete='off' id='newPasswordId' className="row-Register-without-margins"   ></Form.Control>
                    <div className="alt-remember-register"><span id="newPasswordIdError"></span></div>
                     </Form.Group>
                     <Form.Group >
                     <h6 className="row-80-h6"><FormattedMessage id="ConfirmNewPassword"/></h6>
                     <Form.Control type="password"  id='confirmNewPasswordId' autoComplete='off' className="row-Register-without-margins"   ></Form.Control>
                     <div className="alt-remember-register"><span id="confirmNewPasswordIdError"></span></div>
                     </Form.Group>
                     <label className="control-label"><FormattedMessage id="KeyFile"/></label>
                     <input id="keyFileId" type="file" className="form-control row-80-h6"  style={{marginBottom:"1rem"}}/>
                     <div className="alt-remember-register"><span id="keyFileIdError"></span></div>
                     <Form.Group >
                     <Button type="submit" id='ResetPasswordButton' className="btn btn-dark-blue row-80" value="ResetPassword"><FormattedMessage id="ResetPassword"/></Button>
                    </Form.Group>
             </Form>
           </div>
           <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
    </div>
    {active && ( <Loading />)}
    </div>
  )
}

export default ForgotPassword
import React, { useEffect, useState } from "react";
import NewNavBar from "../navbar/new_navbar.js";
import {Button, Form} from 'react-bootstrap';
import axios from "../../context/axios_instance";
import {FormattedMessage,useIntl} from 'react-intl';
import { useLocation,useNavigate } from "react-router-dom";
import * as Functions from '../../functions/functions';
import Footer from "../footer/footer.js";
import Loading from "../loading/loading";

function BookingConfirmation() {
    const {state} = useLocation();
    const [active, setActive] = useState(false);
    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [ICCompanies, setICCompanies] = useState(state.props.allInsuranceCompanies);
    const [services, setServices] = useState(state.props.services);
    const [fees, setFees] = useState(state.props.fees);
    const [doctorFees, setDoctorFees] = useState(state.props.doctorFees);
    const [disease, setDisease] = useState(state.props.title);
    const [speciality, setSpeciality] = useState(state.props.doctor.speciality.name);
    const navigate = useNavigate();
    const bookingModel= {
        countryId:'',
        details: '',
        diseaseId: '',
        doctorPatientLink: [],
        icCode:'',
        isCancellationAllowed:true,
        insuranceCompanyId:'',
        paymentStatus:0,
        paymentMode:'',
        insuranceCompany:{
            name:null
        },
        fullName:null,
        pEmail:null,
        bDay:null,
        message:null,
        messageSelf:null,
        serviceIds:[],
        status:0,
        title:'',
        timeSlotIds:[]
      };

    let patientModel= {
        FirstName: '',
        LastName: '',
        Email: '',
        CPRNummer:'',
        Citizenship:'',
        Gender:0,
        PhoneNumber:'',
        UnencryptedEmail:'',
        Birthday:'',
        InsuranceCompanyId:'',
        City:''
      };

      let translationModel={
        serviceIds:[],
        feeIds:[],
        doctorFeeIds:[],
        specialityId:'00000000-0000-0000-0000-000000000000',
        diseaseId:'00000000-0000-0000-0000-000000000000',
    };
    if(state.props.disease !== null){
        translationModel.diseaseId = state.props.diseaseId;
    }
    translationModel.specialityId = state.props.doctor.specialityId;
    fees.forEach(element => {
        translationModel.feeIds.push(element.fee.feeId);
    });
    doctorFees.forEach(element => {
        translationModel.doctorFeeIds.push(element.doctorFeeId);
    });
    services.forEach(element => {
        translationModel.serviceIds.push(element.serviceId);
    });
    const intl = useIntl();
    let language = localStorage.getItem('lang');
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let date  = new Date(state.props.start);
    let duration =` (${sumDurations(state.props.timeSlots)} min)`;

    function getTranslations(){
        axios.post(`/Patient/Home/GetBookingConfirmationTranslations`, translationModel)
        .then(
          (result) => {
            if(state.props.disease !== null){
                var serviceCode = disease.split(' ')[0];
                var title = serviceCode +" "+result.data.disease.name;
                setDisease(title);
            }
            if(result.data.doctorSpeciality){
                setSpeciality(result.data.doctorSpeciality);
            }
            doctorFees.forEach(elem=>{
                var fee=result.data.doctorFees.find(element => element.doctorFeeId === elem.doctorFeeId);
                if(fee){
                    elem.name = fee.name;
                }
            });
            setDoctorFees(doctorFees);
            services.forEach(elem=>{
                var service=result.data.services.find(element => element.serviceId === elem.serviceId);
                if(service){
                    elem.service.name = service.name;
                }
            });
            setServices(services);
            fees.forEach(elem=>{
                var fee=result.data.fees.find(element => element.feeId === elem.fee.feeId);
                if(fee){
                    elem.fee.name = fee.name; 
                    console.log(fee.name);
                }
            });
            setFees(fees);
          }
        )
    }

    function sumDurations(models) {
        let totalDuration = 0;
        for (let model of models) {
          totalDuration += model.timeSlot.duration;
        }
        return totalDuration;
      }

    function changeDoctorPatientLink(){
       var firstName=Functions.decryptString(state.props.doctorPatientLink.firstName);
       var lastName=Functions.decryptString(state.props.doctorPatientLink.lastName);
       var birthday=Functions.decryptString(state.props.doctorPatientLink.birthday);
       var phoneNumber=Functions.decryptString(state.props.doctorPatientLink.phoneNumber);
       var email=Functions.decryptString(state.props.doctorPatientLink.email);
       if(state.props.doctorPatientLink.citizenship !== null){
        var citizenship=Functions.decryptString(state.props.doctorPatientLink.citizenship);
        state.props.doctorPatientLink.citizenship = Functions.encryptStringForDoctor(citizenship,state.props.doctor.user.key.publicKey);
       }
       if(state.props.doctorPatientLink.cprNummer!==null){
        var cprnumber=Functions.decryptString(state.props.doctorPatientLink.cprNummer);
        state.props.doctorPatientLink.cprNummer = Functions.encryptStringForDoctor(cprnumber,state.props.doctor.user.key.publicKey);
       }
       var Birthdate=Functions.fixDate(birthday);
       Birthdate=Functions.fixDateBackEnd(Birthdate);
       var bDay=Date.parse(Birthdate.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
       state.props.doctorPatientLink.firstName = Functions.encryptStringForDoctor(firstName,state.props.doctor.user.key.publicKey);
       state.props.doctorPatientLink.lastName = Functions.encryptStringForDoctor(lastName,state.props.doctor.user.key.publicKey);
       state.props.doctorPatientLink.birthday = Functions.encryptStringForDoctor(bDay.toString(),state.props.doctor.user.key.publicKey);
       state.props.doctorPatientLink.phoneNumber = Functions.encryptStringForDoctor(phoneNumber,state.props.doctor.user.key.publicKey);
       state.props.doctorPatientLink.email = Functions.encryptStringForDoctor(email,state.props.doctor.user.key.publicKey);
    }

    function ConfirmBookingCCForm(){
        const model = {
            serviceIds:[],
            timeSlotIds:[]
        };
        model.details=document.getElementById('AnamnesisId').value;
        model.diseaseId=state.props.diseaseId;
        model.fullName=Functions.decryptString(state.props.patient.fullName);
        model.pEmail=Functions.decryptString(state.props.patient.email);
        model.bDay=Functions.decryptString(state.props.patient.birthday);
        model.paymentMode=1;
        var mess = document.getElementById('MessageId').value;
        if(mess!=''){
            model.messageSelf=Functions.encryptStringWithoutTags(mess);
            model.message=Functions.encryptStringWithoutTagsForUser(mess,state.props.doctor.user.key.publicKey);
        }
        if(disease === null){
            model.title=document.getElementById('TentativeDiagnosisId').value;
        }else{
            model.title=disease;
        }

        services.forEach(item => {
            model.serviceIds.push(item.serviceId);
        });
        state.props.timeSlots.forEach(item => {
            model.timeSlotIds.push(item.timeSlotId);
        });
        model.isCancellationAllowed=state.props.isCancellationAllowed;
        changeDoctorPatientLink();
        model.doctorPatientLink=state.props.doctorPatientLink;
        model.houseCallDistance=state.props.houseCallDistance;
        if(state.props.houseCall){
            localStorage.setItem("houseCallAddress", `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(destination)}&travelmode=driving`);
            const encryptedData={
                publicKey: state.props.doctor.user.key.publicKey,
                key: "BookingAddress",
                value: Functions.encryptStringForDoctor(destination,state.props.doctor.user.key.publicKey)
            };
            model.encryptedData = encryptedData;
        }
        axios.post(`/Patient/Home/PaymentSetup`,model)
        .then(
          (result) => {
            console.log(result)
            if(result.data.paymentMode===2){
                navigate("/dashboard", { state: { props: result.data.paymentMode}});
            }else{
                window.location.href=result.data.paymentInfo.link;
            }
          }
        )
    }

    function ConfirmBookingICForm(){
        bookingModel.countryId=document.getElementById('CountryId').value;
        bookingModel.details=document.getElementById('AnamnesisId').value;
        bookingModel.insuranceCompanyId=document.getElementById('ICId').value;
        bookingModel.diseaseId=state.props.diseaseId;
        bookingModel.fullName=Functions.decryptString(state.props.patient.fullName);
        bookingModel.pEmail=Functions.decryptString(state.props.patient.email);
        bookingModel.bDay=Functions.decryptString(state.props.patient.birthday);
        if(document.getElementById('CompanyNameId').value !==''){
            bookingModel.insuranceCompany.name=document.getElementById('CompanyNameId').value;
        }
        bookingModel.paymentMode=2;
        var mess = document.getElementById('MessageId').value;
        if(mess!=''){
            bookingModel.messageSelf=Functions.encryptStringWithoutTags(mess);
            bookingModel.message=Functions.encryptStringWithoutTagsForUser(mess,state.props.doctor.user.key.publicKey);
        }
        if(disease === null){
            bookingModel.title=document.getElementById('TentativeDiagnosisId').value;
        }else{
            bookingModel.title=disease;
        }
        services.forEach(item => {
            bookingModel.serviceIds.push(item.serviceId);
        });
        state.props.timeSlots.forEach(item => {
            bookingModel.timeSlotIds.push(item.timeSlotId);
        });
        bookingModel.isCancellationAllowed=state.props.isCancellationAllowed;
        populatePatientModel();
        changeDoctorPatientLink();
        bookingModel.doctorPatientLink=state.props.doctorPatientLink;
        bookingModel.icCode=document.getElementById('PaymentCodeId').value;
        bookingModel.houseCallDistance=state.props.houseCallDistance;
        if(state.props.houseCall){
            bookingModel.houseCallAddress=`https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(destination)}&travelmode=driving`;
            const encryptedData={
                publicKey: state.props.doctor.user.key.publicKey,
                key: "BookingAddress",
                value: Functions.encryptStringForDoctor(destination,state.props.doctor.user.key.publicKey)
            };
            bookingModel.encryptedData = encryptedData;
        }
        axios.post(`/Patient/Home/PaymentSetup`,bookingModel)
        .then(
          (result) => {
            console.log(result.data);
            if(result.data.paymentMode===2){
                axios.get(`Home/GetInsuranceSharedKey`)
                .then(
                  (result) => {
                    if(result.data !== null){
                        SaveICPatientLink(result.data.publicKey);
                    }
                   navigate("/dashboard", { state: { props: result.data.paymentMode}});
                  }
                )
                
            }
          }
        )
    }

    function SaveICPatientLink(icPublicKey) {
        let patientICModel = {
            FirstName: Functions.encryptStringForDoctor(patientModel.FirstName,icPublicKey),
            LastName: Functions.encryptStringForDoctor(patientModel.LastName,icPublicKey),
            Email: Functions.encryptStringForDoctor(patientModel.Email,icPublicKey),
            CPRNummer: Functions.encryptStringForDoctor(patientModel.CPRNummer,icPublicKey),
            Citizenship: Functions.encryptStringForDoctor(patientModel.Citizenship,icPublicKey),
            PhoneNumber: Functions.encryptStringForDoctor(patientModel.PhoneNumber,icPublicKey),
            City: Functions.encryptStringForDoctor(patientModel.City,icPublicKey),
            Gender: parseInt(patientModel.Gender),
            UnencryptedEmail: patientModel.Email,
            InsuranceCompanyId: patientModel.InsuranceCompanyId,
            Birthday: Functions.encryptStringForDoctor(patientModel.Birthday,icPublicKey)
        };
        axios.post(`/Home/SavePatientICData`,patientICModel)
        .then(
          (result) => {
          }
        )
}
    function populatePatientModel(){
        patientModel.FirstName = Functions.decryptString(state.props.doctorPatientLink.firstName);
        patientModel.LastName = Functions.decryptString(state.props.doctorPatientLink.lastName);
        patientModel.Email = Functions.decryptString(state.props.doctorPatientLink.email);
        if(state.props.doctorPatientLink.cprNummer!==null){
            var cprnumber=Functions.decryptString(state.props.doctorPatientLink.cprNummer);
            patientModel.CPRNummer = cprnumber;
           }
        if(state.props.doctorPatientLink.citizenship !== null){
            var citizenship=Functions.decryptString(state.props.doctorPatientLink.citizenship);
            patientModel.Citizenship = citizenship;
           }
        patientModel.PhoneNumber = Functions.decryptString(state.props.doctorPatientLink.phoneNumber);
        patientModel.Gender = state.props.patient.gender;
        patientModel.City = Functions.decryptString(state.props.patient.googleAddress);
        patientModel.Birthday = Functions.decryptString(state.props.doctorPatientLink.birthday);
        patientModel.InsuranceCompanyId = document.getElementById('ICId').value;
    }

    function getOption(item){
        return <option value={item.value}>{item.text}</option>;
    }

    function getService(item){
        var itemCatalog=state.props.doctor.catalog.find(element => element.serviceId === item.serviceId);
        return  <div style={{display:"flex",width:"100%"}}>
        <p style={{width:"80%"}}>{item.service.name}</p>
        <p style={{width:"20%",textAlign:"right"}}>{itemCatalog.price} {state.props.doctorsCulture.numberFormat.currencySymbol}</p>
        </div>;
    }
    
    function getFee(item){
        return  <div style={{display:"flex",width:"100%"}}>
        <p style={{width:"80%"}}>{item.name}</p>
        <p style={{width:"20%",textAlign:"right"}}>{item.price} {state.props.doctorsCulture.numberFormat.currencySymbol}</p>
        </div>;
    }

    useEffect(() => {
        document.getElementById("header").scrollIntoView();
        let hasServices = false;
        setDestination(state.patientAddress);
        setOrigin(state.props.doctor.address.address);
        if (services.filter(a => a.service.type !== 0).length !== 0) {
            hasServices = true;
        }
        if(state.props.allInsuranceCompanies !== null && hasServices===true){ 
            document.getElementById("RegisterButton").disabled = true;
        }
        if(state.props.statement){
            document.getElementById('TentativeDiagnosisId').value=intl.formatMessage({id: 'Statement'}); 
        }
        if(state.props.houseCall){
            document.getElementById('TentativeDiagnosisId').value=intl.formatMessage({id: 'HouseCall'}); 
        }
        getTranslations();
    },[]);
  
    function GoToDashbord(){
        window.location.href='/dashboard';
    }
    function isChecked(){
        var btn = document.getElementById("RegisterButton");
        var chk = document.getElementById("Terms");
        if (chk.checked === true) {
            btn.disabled = false;
        } else {
            btn.disabled = true;
        };
      }
    function ConfirmBookingCC(){
        document.getElementById('AnamnesisIdError').innerHTML="";
        let anamnesis = document.getElementById('AnamnesisId').value;
        if(disease === null ){
            document.getElementById('TentativeDiagnosisIdError').innerHTML="";
            let title = document.getElementById('TentativeDiagnosisId').value;
            if(title==="" && !state.props.houseCall && !state.props.statement){
                document.getElementById('TentativeDiagnosisIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                document.getElementById('header').scrollIntoView();
            }else if(anamnesis===""){
                document.getElementById('AnamnesisIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                document.getElementById('header').scrollIntoView();
              }
             else{
                setActive(true);
                ConfirmBookingCCForm();
              }

        }else{
            if(anamnesis===""){
                document.getElementById('AnamnesisIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                document.getElementById('header').scrollIntoView();
              }
             else{
                setActive(true);
                ConfirmBookingCCForm();
              }
        } 

    }
    function ConfirmBookingIC(){
        
        document.getElementById('PaymentCodeIdError').innerHTML="";
        document.getElementById('ICIdError').innerHTML="";
        document.getElementById('AnamnesisIdError').innerHTML="";
        document.getElementById('CompanyNameIdError').innerHTML='';
        let payment = document.getElementById('PaymentCodeId').value;
        let IC = document.getElementById('ICId').value;
        let anamnesis = document.getElementById('AnamnesisId').value;
        if(disease === null ){
            document.getElementById('TentativeDiagnosisIdError').innerHTML="";
            let title = document.getElementById('TentativeDiagnosisId').value;
            if(title==="" && !state.props.houseCall && !state.props.statement){
                document.getElementById('TentativeDiagnosisIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                document.getElementById('header').scrollIntoView();
            }
            else if(anamnesis===""){
                document.getElementById('AnamnesisIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                document.getElementById('header').scrollIntoView();
              }
              else if(IC==='NA'){
                document.getElementById('ICIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                document.getElementById('header').scrollIntoView();
              }else  if(IC==="00000000-0000-0000-0000-000000000000" && document.getElementById('CompanyNameId').value===''){
                    document.getElementById('CompanyNameIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                    document.getElementById('header').scrollIntoView();
              }
              else if(payment===""){
                document.getElementById('PaymentCodeIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                document.getElementById('header').scrollIntoView();
              }else{
                setActive(true);
                ConfirmBookingICForm();
              }
        }else{
            if(anamnesis===""){
                document.getElementById('AnamnesisIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                document.getElementById('header').scrollIntoView();
              }
              else if(IC==='NA'){
                document.getElementById('ICIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                document.getElementById('header').scrollIntoView();
              }else  if(IC==="00000000-0000-0000-0000-000000000000" && document.getElementById('CompanyNameId').value===''){
                    document.getElementById('CompanyNameIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                    document.getElementById('header').scrollIntoView();
              }
              else if(payment===""){
                document.getElementById('PaymentCodeIdError').innerHTML=intl.formatMessage({id: 'RequiredField'});
                document.getElementById('header').scrollIntoView();
              }else{
                setActive(true);
                ConfirmBookingICForm();
              }
        }
    }
    function NewIC(){
        let IC = document.getElementById('ICId').value;
        document.getElementById('CompanyNameId').value='';
        document.getElementById('CompanyNameIdError').innerHTML='';
        if(IC==="00000000-0000-0000-0000-000000000000"){
            document.getElementById('CompanyNameDivId').style.display="flex";
        }else{
            document.getElementById('CompanyNameDivId').style.display="none";
        }
    }

    function getICByCountry(){
        let countryId = document.getElementById('CountryId').value;
        axios.get(`Patient/Home/GetICListByCountry/${countryId}`)
        .then(
          (result) => {
            if(result.data !== ""){
                setICCompanies(result.data);
            }
          }
        )
    }

    function getICDiv(){
        if(state.props.allInsuranceCompanies !== null){
            return  <div  style={{width:"100%",display:"contents"}}>
            <div style={{marginBottom:"10px"}}>
                <b><FormattedMessage id="Insurancedetails"/></b>
            </div>  
            <div style={{display:"flex",width:"100%"}}>

            <b style={{width:"40%",marginBottom:"10px"}}><FormattedMessage id="Country"/></b>
            <p style={{width:"60%"}}><Form.Select id="CountryId" onChange={getICByCountry}>
                                    {state.props.allCountries.map(item => (getOption(item)))}
                                    </Form.Select></p>
            </div>
            <div style={{display:"flex",width:"100%"}}>
            <b style={{width:"40%"}}><FormattedMessage id="ICompany"/></b>
            <p style={{width:"60%"}}><Form.Select  id="ICId" onChange={NewIC}>
                                    {ICCompanies.map(item => (getOption(item)))}
                                    </Form.Select></p>
            </div>
            <div className="error-message" style={{marginBottom:"15px"}}><span id="ICIdError"></span></div>
            <div style={{display:"none",width:"100%"}} id="CompanyNameDivId">
            <b style={{width:"40%"}}><FormattedMessage id="Name"/></b>
            <p style={{width:"60%"}}><Form.Control type="text" id="CompanyNameId"/></p>
            </div>
            <div className="error-message" style={{marginBottom:"15px",}}><span id="CompanyNameIdError"></span></div>
            <div style={{display:"flex",width:"100%"}}>
            <b style={{width:"40%"}}><FormattedMessage id="Paymentcode"/></b>
            <p style={{width:"60%"}}><Form.Control type="text" id="PaymentCodeId"/></p>
            </div>
            <div className="error-message"><span id="PaymentCodeIdError"></span></div>
            <div><FormattedMessage id="InsuranceCompanyPaymentCodeText"/></div></div> 
        }
    }
    function getAcceptDiv(){
        let hasServices = false;
        if (services.filter(a => a.service.type !== 0).length !== 0) {
            hasServices = true;
          }

        if(state.props.allInsuranceCompanies !== null && hasServices===true){
            return <div>  <div style={{display:"flex",width:"100%"}}>
            <div style={{width:"10%"}}><input type="checkbox" onChange={isChecked} value="Terms" id="Terms"/> </div>
            <div style={{width:"90%"}}><label for="Terms"><FormattedMessage id="IAccept"/></label></div>
            </div>
            <br></br></div>
        }else if(hasServices!==true){
            return <div><div style={{display:"flex",width:"100%",color:"red"}}>
            <FormattedMessage id="BookingConfirmationNotAllowed"/>
            </div>
            <br></br></div>
        }
    }
    function getButton(){
        let hasServices = false;
        if (services.filter(a => a.service.type !== 0).length !== 0) {
            hasServices = true;
        }
        if(hasServices===true){
            if(state.props.allInsuranceCompanies !== null){
                return   <Button  variant="success" onClick={ConfirmBookingIC} id='RegisterButton' className="row-45" ><FormattedMessage id="Confirm"/></Button>;
            }else{
                return   <Button  variant="success" onClick={ConfirmBookingCC} id='RegisterButton' className="row-45" ><FormattedMessage id="Confirm"/></Button>;
            }
        }
    }

    function getTitle(){
        if(disease === null){
          return <div style={{width:"100%",marginBottom:"15px"}} ><div style={{display:"flex",width:"100%"}}>
            <b style={{width:"40%"}}><FormattedMessage id="TentativeDiagnosis"/></b>
            <p style={{width:"60%"}}><Form.Control type="text" id="TentativeDiagnosisId"/></p>
            </div>
            <div className="error-message"><span id="TentativeDiagnosisIdError"></span></div>
            </div>;
            
        }else{
            return <div style={{display:"flex",width:"100%"}}>
            <b style={{width:"40%",marginBottom:"10px"}}><FormattedMessage id="TentativeDiagnosis"/></b>
            <p style={{width:"60%"}}>{disease}</p>
            </div>;
        }
    }
   

    return (
        <div>
              <NewNavBar />
             <div className="pageD container">
                    <div className="coverD">
                        <h4 id="header"><FormattedMessage id="BookingConfirmation"/></h4>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"40%"}}><FormattedMessage id="Patient"/></b>
                        <p style={{width:"60%"}}>{Functions.decryptString(state.props.patient.fullName)}</p>
                        </div>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"40%"}}><FormattedMessage id="Date"/></b>
                        <p style={{width:"60%"}}>{date.toLocaleDateString(language, options)}</p>
                        </div>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"40%"}}><FormattedMessage id="Time"/></b>
                        <p style={{width:"60%"}}>{state.props.start.toString().substring(11,16)} {duration}</p>
                        </div>
                        {getTitle()}
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"40%"}}><FormattedMessage id="Anamnesis"/></b>
                        <p style={{width:"60%"}}><Form.Control type="text" id="AnamnesisId"/></p>
                        </div>
                        <div className="error-message"><span id="AnamnesisIdError"></span></div>
                    </div>
                    <div className="coverD">
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"40%"}}><FormattedMessage id="Doctor"/></b>
                        <p style={{width:"60%"}}>{state.props.doctor.fullName}</p>
                        </div>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"40%"}}><FormattedMessage id="Speciality"/></b>
                        <p style={{width:"60%"}}>{speciality}</p>
                        </div>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"40%",marginBottom:"10px"}}><FormattedMessage id="Address"/></b>
                        <p style={{width:"60%"}}>{state.props.doctor.address.address}</p>
                        </div>
                        {getICDiv()}
                    </div>
                    <div className="coverD">
                        <h4><FormattedMessage id="Servicesrelevant"/>{disease}</h4>
                        <div style={{width:"100%"}}>
                        
                        <div style={{display:"flex"}}>
                            <b style={{width:"80%"}}><FormattedMessage id="Name"/></b>
                            <b style={{width:"20%",textAlign:"right"}}><FormattedMessage id="Price"/></b>
                         </div>
                        <hr
                        style={{
                        background: '#727272',
                        color: '#727272',
                        borderColor: '#727272',
                        height: '3px',
                        }}
                    />
                        </div>
                        {services.map(item => (getService(item)))}
                        {doctorFees.map(item => (getFee(item)))}
                        <div style={{display:"flex",width:"100%"}}>
                        <p style={{width:"80%"}}>
                            {fees.map(item => item.fee.name)}
                        </p>
                        <p style={{width:"20%",textAlign:"right"}}>{fees.reduce((accumulator, fee) => accumulator + fee.fee.amount, 0)} {state.props.doctorsCulture.numberFormat.currencySymbol}</p>
                        </div>
                       <div style={{display:"flex",width:"100%"}}>
                        <p style={{width:"80%"}}><FormattedMessage id="Total"/></p>
                        <p style={{width:"20%",textAlign:"right"}}>{state.props.price} {state.props.doctorsCulture.numberFormat.currencySymbol}</p>
                        </div>
                        <div style={{display:"flex",width:"100%"}}>
                        <b style={{width:"40%"}}><FormattedMessage id="MessageDoctor"/></b>
                        <p style={{width:"60%"}}><Form.Control type="text" id="MessageId" /></p>
                        </div>
                        <br></br>
                       <div style={{width:"100%"}}>
                            {getAcceptDiv()}
                            <div className="flexDiv100">
                            <Button  variant="outline-secondary" onClick={GoToDashbord} className="row-45"><FormattedMessage id="Cancel"/></Button>
                            {getButton()}
                            </div>
                        </div>
                    </div>
                    <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
             </div>
             {active && ( <Loading />)}
        </div>
      
      );
  }
  export default BookingConfirmation;
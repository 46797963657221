import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import PatientProfile from './components/patient/profile/profile';
import DoctorProfile from './components/doctor/profile/profile';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Login from "./components/login/login";
import Dashboard from "./components/dashboard/dashboard";
import {IntlProvider} from "react-intl";
import messages_da from "./translations/da.json";
import messages_en from "./translations/en.json";
import Register from './components/patient/register/register';
import BookingConfirmation from './components/bookings/booking_confirmation.js';
import Checkout from './components/checkout/checkout';
import SearchDashboard from './components/dashboard/search_dashboard';
import Introduction from './components/introduction/introduction';
import Chat from './components/chat/chat';
import Details from './components/bookings/details';
import ClosedPeriod from './components/closedperiod/closed_period';
import Response from './components/emailresponse/response';
import Terms from './components/terms/terms';
import AboutUs from './components/about_us/about_us';
import DoctorLogin from './components/login/doctor_login';
import FAQ from './components/introduction/faq';
import {config} from './Constants';
import TagManager from 'react-gtm-module';
import Security from './components/security/security.js';
import ForgotPassword from './components/login/forgot_password.js';
const messages = {
  'da': messages_da,
  'en': messages_en
};
let language = localStorage.getItem('lang');
if(language==null){
 localStorage.setItem('lang', 'da');
 language = localStorage.getItem('lang');
}
if(config.enviroment==='live'){
  TagManager.initialize({ gtmId: 'GTM-MQVW5GZ' });
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <IntlProvider locale={language} messages={messages[language]}>
  <BrowserRouter>
     <Routes>
      <Route path="/" element={<SearchDashboard />} />
      <Route path="/response" element={<Response />} />
      <Route path="/login" element={<Login />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/security" element={<Security />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/doctorlogin" element={<DoctorLogin />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/patient/faq" element={<FAQ />} />
      <Route path="/patient/profile" element={<PatientProfile />} />
      <Route path="/doctor/profile" element={<DoctorProfile />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/details" element={<Details />} />
      <Route path="/closedperiod" element={<ClosedPeriod />} />
      <Route path="/instruction" element={<Introduction />} />
      <Route path="/patient/register" element={<Register />} />
      <Route path="/patient/bookingconfirmation" element={<BookingConfirmation />} />
   </Routes>
 </BrowserRouter>
 </IntlProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import CloseButton from 'react-bootstrap/CloseButton';
const Popup = props => {
    return (
      <div className="popup-box">
        <div className="box">
          <div style={{float:"right"}}><span className="close-icon" onClick={props.handleClose}><CloseButton /></span></div>
          {props.content}
        </div>
      </div>
    );
  };
export default Popup;
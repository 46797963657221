import React, { useEffect } from "react";
import {FormattedMessage} from 'react-intl';
import NewNavBar from "../navbar/new_navbar.js";
import Footer from "../footer/footer.js";
const FAQ = () => {

    return ( <div>
          <NewNavBar />
         <div className="pageD container">
         <div className="coverD">
            <h4><FormattedMessage id="FAQ"/></h4>
            <h6><b><FormattedMessage id="Question1"/></b></h6>
            <FormattedMessage id="Response1" /><br/><br/>
            <h6><b><FormattedMessage id="Question2"/></b></h6>
            <FormattedMessage id="Response2" /><br/><br/>
            <h6><b><FormattedMessage id="Question3"/></b></h6>
            <FormattedMessage id="Response3" /><br/><br/>
            <h6><b><FormattedMessage id="Question4"/></b></h6>
            <FormattedMessage id="Response4" /><br/><br/>
            <h6><b><FormattedMessage id="Question5"/></b></h6>
            <FormattedMessage id="Response5" /><br/><br/>
            <h6><b><FormattedMessage id="Question6"/></b></h6>
            <FormattedMessage id="Response6" /><br/><br/>
            <h6><b><FormattedMessage id="Question7"/></b></h6>
            <FormattedMessage id="Response7" /><br/><br/>
            <h6><b><FormattedMessage id="Question8"/></b></h6>
            <FormattedMessage id="Response8" /><br/><br/>
            <h6><b><FormattedMessage id="Question9"/></b></h6>
            <FormattedMessage id="Response9" /><br/><br/>
            <h6><b><FormattedMessage id="Question10"/></b></h6>
            <FormattedMessage id="Response10" /><br/><br/>
        </div>
        <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
         </div>
        </div>)
}
export default FAQ;
import React, { useEffect, useState } from "react";
import axios from "../../context/axios_instance";
import NewNavBar from "../navbar/new_navbar.js";
import { useLocation} from "react-router-dom";
import Roles from '../../roles';
import * as Functions from '../../functions/functions';
import { FaPaperPlane } from "react-icons/fa";
import {FormattedMessage,useIntl} from 'react-intl';
import Footer from "../footer/footer.js";
function Chat() {
    const [isLoaded, setIsLoaded] = useState(false);
    const {state} = useLocation();
    const intl = useIntl();
    const [userId, setUserId] = useState(null);
    const [doctorId, setDoctorId] = useState(null);
    const [resultModel, setModel] = useState([]);
    const roles = localStorage.getItem('roles');
    useEffect(() => {
        console.log(state);
        GetConversation();
    }, [])

    function GetConversation(){
      axios.get('Home/GetConversation/'+state.id)
      .then(
        (result) => {
          setIsLoaded(true);
          if (roles.includes(Roles.Doctor)) {
              setUserId(result.data.doctor.userId);
          }
          setDoctorId(result.data.doctor.userId);
          setModel(result.data);
          console.log(result.data);
          setTimeout(() => {
            document.getElementById("scroll-to-bottom").scrollIntoView();
          }, 1000);
          
        },
        (error) => {
          setIsLoaded(true);
        }
      )
    }
     
    function SendMessage(){
      var message=document.getElementById("messageId").value;
      document.getElementById("messageId").value="";
      const model = {
        doctorName:"",
        patientName:"",
        patientEmail:"",
        bookingId: resultModel.relatedObjectId,
        messageSelf:Functions.encryptStringWithoutTags(message),
        message:Functions.encryptStringWithoutTagsForUser(message,resultModel.recipientsPublicKey)
      };
      //Patient case
      if(userId===null){
        model.doctorName=resultModel.doctor.fullName;
        model.patientName=Functions.decryptString(resultModel.patient.firstName)+" "+Functions.decryptString(resultModel.patient.lastName);
      }//Doctor case
      else{
        model.doctorName=resultModel.doctor.fullName;
        model.patientName=state.name;
        model.patientEmail=Functions.decryptString(resultModel.doctorPatientLink.email);
      }
      console.log(model);
      axios.post(`/Home/SendMessage`,model)
      .then(
        (result) => {
          console.log(result.data)
          if(result.data.succeeded){
            GetConversation();
          }
        }
      )
    }
    
    function getMessage(message){
        //Patient case
        if(userId===null){
            if(doctorId !== message.recipientId){
                return <div className="message-row">
                           <div className="col-12"> 
                              <span className="message shadow-sm d-inline-block p-3 pl-3 pr-3 pt-2 pb-2 mb-1 message-left" >{Functions.decryptStringWithoutTags(message.textMessage)}</span>
                           </div>
                        </div>;
            }else{
                return <div  className="message-row">
                          <div className="col-12">
                            <span className="message shadow-sm d-inline-block p-3 pl-3 pr-3 pt-2 pb-2 mb-1 bg-primary text-white float-right message-right">{Functions.decryptStringWithoutTags(message.textMessage)}</span>
                          </div>
                       </div>;
            }

        }//Doctor case
        else{
          if(doctorId === message.recipientId){
            return <div className="message-row">
                    <div className="col-12"> 
                      <span className="message shadow-sm d-inline-block p-3 pl-3 pr-3 pt-2 pb-2 mb-1 message-left" >{Functions.decryptStringWithoutTags(message.textMessage)}</span>
                    </div>
         </div>;
          }else{
              return <div  className="message-row">
                          <div className="col-12">
                            <span className="message shadow-sm d-inline-block p-3 pl-3 pr-3 pt-2 pb-2 mb-1 bg-primary text-white float-right message-right">{Functions.decryptStringWithoutTags(message.textMessage)}</span>
                          </div>
                      </div>; }
        }
    }


    if (!isLoaded) {
      return <div></div>;
    } else {
      return (
        <div>
               <NewNavBar />
              <div className="pageD container d-block">
                  <div className="coverD" style={{paddingLeft:"0px",paddingRight:"0px"}}>
                      <h4>{state.name}</h4>
                      <div className="col-12">
                        <span style={{marginTop:"-30px",marginRight:"7px",float:"right"}}><a href="/dashboard"><FormattedMessage id="Back"/></a></span>
                      </div>
                      <div className="conversation-div-style">
                        {resultModel.messages.map(message => (getMessage(message)))}
                        <div id="scroll-to-bottom"></div>
                      </div>
                      <div style={{width:"100%"}} className="message-row">
                            <div className="col-12" style={{display:"flex",marginTop:"10px"}}>
                               <div style={{width:"100%",marginRight:"10px"}}>
                                    <input type="text"  className="form-control rounded-pill" id="messageId" placeholder={intl.formatMessage({id: 'TypeMessage'})}></input>
                                </div>
                                <div>
                                  <a style={{float:"right",borderRadius:"20px",height:"2.5rem"}} id="sendMessageBtn" onClick={(e) => SendMessage()} className="btn btn-dark-blue"><FaPaperPlane style={{marginTop:"-11px"}}/></a>
                                </div>
                             </div>
                        </div>
                  </div>
                  <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
              </div>
      </div>
      );
    }
  }
  export default Chat;
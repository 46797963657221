import React , { useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: 'auto',
  height: '300px'
};

function Map(props) {
  
    const [cAddress, setCAddress] = useState([]);
    const [propsAddress, setPropsAddress] = useState(null);
    try {
    const google = window.google;
    var geocoder = new google.maps.Geocoder();
  } catch (exceptionVar) {
    window.location.reload();
  }
    if(cAddress===''){
        if(props.address !==null && props.address !== propsAddress){
            setPropsAddress(props.address);
        }
        geocoder.geocode({ address: props.address }, (results, status) => {
            if (status === "OK") {
                const uluru = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
                setCAddress(uluru);
            } 
        });
    }
    if(propsAddress !==props.address){
        setPropsAddress(props.address);
        geocoder.geocode({ address: props.address }, (results, status) => {
            if (status === "OK") {
                const uluru = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
                setCAddress(uluru);
            } 
        });
    }
    
  return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={cAddress}
        zoom={18}
      >
        <Marker
      position={cAddress}
    />
      </GoogleMap>
  )
  
}

export default Map
import axios from "../../context/axios_instance";
import {FormattedMessage} from 'react-intl';
// import * as Functions from '../../functions/functions';
import Dropdown from 'react-bootstrap/Dropdown';
import Roles from '../../roles';
import {config} from '../../Constants';
import { FaBars } from "react-icons/fa";
import React, {useState, useEffect} from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const NewNavBar = () => {
    const isLoggedIn = localStorage.getItem('access_token');
    const roles = localStorage.getItem('roles');
    const lang = localStorage.getItem('lang');
    const [isOpen, setIsopen] = useState(false);
    let isMobile=false;
   if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
           isMobile=true;
    }

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    }

    const LogoutButton = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('publicKeyCookie');
        localStorage.removeItem('privateKeyCookie');
        localStorage.removeItem('encryptionTestCookie');
        localStorage.removeItem('roles');
        localStorage.removeItem('userId');
        window.location.href="/";
    }

    useEffect(() => {
        if(isLoggedIn){
            axios.get('Home/CheckIfUserLoggedIn')
            .then(
              (result) => {
              }
            )
        }
    }, [])

    const goToDoctor = () => {
        window.location.href=config.url.WEB_URL;
    }

    const goToPatient = () => {
        window.location.href="/";
    }

    const LoginAsDoctor = () => {
        if(isMobile){
            window.location.href="/doctorlogin";
        }else{
            window.location.href=config.url.WEB_URL+"Identity/Account/Login";
        }
    }

    function getLoginButtonLeft(){
        if(isMobile){
            if(isLoggedIn){
                return <div className="btn btn-dark-blue" style={{padding:"3px 13px",marginRight:"-20px"}} id="HeaderNavigateToLoginButton" onClick={LogoutButton} ><FormattedMessage id="Logout"/> </div>;
            }else{
                return <Dropdown>
                <Dropdown.Toggle className="btn btn-dark-blue" style={{padding:"3px 13px"}}  id="HeaderNavigateToLoginButton">
                <FormattedMessage id="Login"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/login"><FormattedMessage id="LoginAsPatient"/></Dropdown.Item>
                  <Dropdown.Item onClick={LoginAsDoctor}><FormattedMessage id="LoginAsDoctor"/></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>;
               }
        }
    }

    function getLoginButtonRight(){
        if(!isMobile){
            if(isLoggedIn){
                return <div className="btn btn-dark-blue" style={{padding:"3px 13px",marginRight:"6px"}} id="HeaderNavigateToLoginButton" onClick={LogoutButton} ><FormattedMessage id="Logout"/> </div>;
            }else{
            return <Dropdown>
            <Dropdown.Toggle className="btn btn-dark-blue" style={{padding:"3px 13px",marginRight:"6px"}}  id="HeaderNavigateToLoginButton">
            <FormattedMessage id="Login"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/login"><FormattedMessage id="LoginAsPatient"/></Dropdown.Item>
              <Dropdown.Item onClick={LoginAsDoctor}><FormattedMessage id="LoginAsDoctor"/></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>;
           }
        }
    }

    function getLanguageButton(){
        // if(!isMobile){
            let english='';
            let danish='';
            let src = "https://here2testdoc.here2treat.com/images/da-DK.png";
            if (lang === "en") {
                english =  "active-language";
                src = "https://here2testdoc.here2treat.com/images/en-GB.png"
            }else{
                danish =  "active-language";
            }
            return <Dropdown style={{marginLeft:"6px"}}>
            <Dropdown.Toggle className="btn btn-dark-blue language-button" style={{padding:"4px 12px", marginRight:"-5px", border: 'none'}}  id="HeaderNavigateToLoginButton">
                <img src={src} width={16} height={16} alt="Image Button" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className={"sd-link "+english} onClick={() => ChangeLanguage('en-GB')}><FormattedMessage id="English"/></Dropdown.Item>
              <Dropdown.Item className={"sd-link "+danish} onClick={() => ChangeLanguage('da-DK')}><FormattedMessage id="Dansk"/></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>;
        // }
    }

    function getDoctorClass(){
        if(roles !== null && roles.includes(Roles.Doctor)){
            return "user-role active-role";
        }else{
            return "user-role";
        }  
    }
    function getPatientClass(){
        if(roles === null || !roles.includes(Roles.Doctor)){
            return "user-role active-role";
        }else{
            return "user-role";
        }  
    }
    function ChangeLanguage(input){
        let lang = input.split(/[-_]/)[0];
        if (isLoggedIn !== null) {
        axios.get('Home/ChangeLanguage/'+input).then((response)=>{
            console.log(response.data);
            localStorage.setItem('lang', lang);
            window.location.reload();
            });
        }else{
            localStorage.setItem('lang', lang);
            window.location.reload();
        }
    }

    function getMenuList(){
        let english='';
        let danish='';
        if (lang === "en") {
            english =  "active-language";
        }else{
            danish =  "active-language";
        }
        if(isLoggedIn === null){
            if(!isMobile){
                return <div className="row row-header">
                <div className="col-md-12">
                <h4><FormattedMessage id="Patient"/></h4><hr></hr>
                    <ul>
                    <li><a className="sd-link" href="/patient/register"><FormattedMessage id="Register"/></a></li>
                    <li><a className="sd-link" href="/terms"><FormattedMessage id="TermsConditions"/></a></li>
                    <li><a className="sd-link" href="/instruction"><FormattedMessage id="Instructions"/></a></li>
                    <li><a className="sd-link" href="/patient/faq"><FormattedMessage id="FAQ"/></a></li>
                </ul>
                </div>
            </div>;
            }else{
                return <div className="row row-header">
                <div className="col-md-6">
                <h4><FormattedMessage id="Patient"/></h4><hr></hr>
                    <ul>
                    <li><a className="sd-link" href="/patient/register"><FormattedMessage id="Register"/></a></li>
                    <li><a className="sd-link" href="/login"><FormattedMessage id="LoginAsPatient"/></a></li>
                    <li><a className="sd-link" href="/terms"><FormattedMessage id="TermsConditions"/></a></li>
                    <li><a className="sd-link" href="/instruction"><FormattedMessage id="Instructions"/></a></li>
                    <li><a className="sd-link" href="/patient/faq"><FormattedMessage id="FAQ"/></a></li>
                </ul>
                </div>
                <div className="col-md-6">
                    <h4><FormattedMessage id="Doctor"/></h4><hr></hr>
                    <ul>
                    <li><a className="sd-link" onClick={LoginAsDoctor}><FormattedMessage id="LoginAsDoctor"/></a></li>
                </ul>
                </div>
            </div>;
            }
        }else if(roles !== null && roles.includes(Roles.Doctor)){
            if(!isMobile){
                return <div className="row row-header">
                <div className="col-md-12">
                    <h4><FormattedMessage id="Doctor"/></h4><hr></hr>
                    <ul>
                    <li><a className="sd-link" href="/dashboard"><FormattedMessage id="Dashboard"/></a></li>
                    <li><a className="sd-link" href="/closedperiod"><FormattedMessage id="ClosedPeriod"/></a></li>
                    <li><a className="sd-link" href="/terms"><FormattedMessage id="TermsConditions"/></a></li>
                </ul>
                </div>
            </div>;
            }else{
                return <div className="row row-header">
                <div className="col-md-12">
                    <h4><FormattedMessage id="Doctor"/></h4><hr></hr>
                    <ul>
                    <li><a className="sd-link" onClick={LogoutButton} ><FormattedMessage id="Logout"/></a></li>
                    <li><a className="sd-link" href="/dashboard"><FormattedMessage id="Dashboard"/></a></li>
                    <li><a className="sd-link" href="/closedperiod"><FormattedMessage id="ClosedPeriod"/></a></li>
                    <li><a className="sd-link" href="/terms"><FormattedMessage id="TermsConditions"/></a></li>
                </ul>
                </div>
            </div>;
            }
        }
        else if(roles !== null && roles.includes(Roles.Patient)){
            if(!isMobile){
                return <div className="row row-header">
            <div className="col-md-12">
                <h4><FormattedMessage id="Patient"/></h4><hr></hr>
                <ul>
                <li><a className="sd-link" href="/dashboard"><FormattedMessage id="Dashboard"/></a></li>
                <li><a className="sd-link" href="/patient/profile"><FormattedMessage id="Profile"/></a></li>
                <li><a className="sd-link" href="/"><FormattedMessage id="NewBooking"/></a></li>
                <li><a className="sd-link" href="/security"><FormattedMessage id="Security"/></a></li>
                <li><a className="sd-link" href="/terms"><FormattedMessage id="TermsConditions"/></a></li>
            </ul>
            </div>
        </div>;
            }else{
                return <div className="row row-header">
            <div className="col-md-12">
                <h4><FormattedMessage id="Patient"/></h4><hr></hr>
                <ul>
                <li><a className="sd-link" onClick={LogoutButton} ><FormattedMessage id="Logout"/></a></li>
                <li><a className="sd-link" href="/dashboard"><FormattedMessage id="Dashboard"/></a></li>
                <li><a className="sd-link" href="/patient/profile"><FormattedMessage id="Profile"/></a></li>
                <li><a className="sd-link" href="/"><FormattedMessage id="NewBooking"/></a></li>
                <li><a className="sd-link" href="/security"><FormattedMessage id="Security"/></a></li>
                <li><a className="sd-link" href="/terms"><FormattedMessage id="TermsConditions"/></a></li>
            </ul>
            </div>
        </div>;
            }
        }else{
            return "";
        }
    }


    function getLogo(){
        if(config.enviroment==='live'){
            return "navbar-icon-live";
        }else if(config.enviroment==='demo'){
            return "navbar-icon-demo";
        }
        else{
            return "navbar-icon-test";
        }  
    }

     
    return (
                <div className="header-div">
                            <header style={{maxWidth:"1140px",padding:"0px 15px"}}>
                                    <section className="social-nav social-nav__header">
                                        {/* {getLoginButtonLeft()} */}
                                        <div id="PatientTab" className={getPatientClass()} onClick={goToPatient} ><FormattedMessage id="Patient"/></div>
                                        <div id="DoctorTab" className={getDoctorClass()} onClick={goToDoctor}><FormattedMessage id="Doctor"/></div>
                                    </section>
                                    <a href="/" className="header-logo"><div className={getLogo()}></div></a>
                                    <div>
                                            <nav>
                                                <div className="container-fluid p-2" style={{textAlign:"right"}}>
                                                    <div style={{display:"inline-flex"}}>
                                                    {getLoginButtonRight()}
                                                        <div className="btn btn-dark-blue" id="HeaderHamburgerMenu" style={{padding:"3px 13px"}} onClick={ToggleSidebar} >
                                                            <i><FaBars/></i>
                                                        </div>
                                                    {getLanguageButton()}
                                                    </div>
                                                </div>
                                            </nav>
                                            <div className={`sidebar ${isOpen === true ? 'active' : ''}`}>
                                                <div className="sd-body">
                                                    {getMenuList()}
                                                </div>
                                            </div>
                                            <div className={`sidebar-overlay ${isOpen === true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
                                </div>
                                </header>
                                <TawkMessengerReact
                                propertyId="64e4bab7cc26a871b030b3f2"
                                widgetId="1h8eninnf"/>
                </div>
            )
  };
  export default NewNavBar;
import React, { useState,useEffect } from "react"; 
import {config} from '../../Constants';
import { FaInfoCircle,FaRegTimesCircle } from "react-icons/fa";

function TargetText(param) {
    const [data, setData] = useState([]);
    var userId = localStorage.getItem('userId');
    useEffect(() => {
        if(param!==undefined){
            let targetTextModel = {
                name : param.name,
                culture : localStorage.getItem('lang')
             };
             
              if(userId !== null){
                targetTextModel.userId = userId;
              }
              
            fetch(config.url.BASE_URL+'Home/GetTargetText',{
                method:'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(targetTextModel)
                })
                .then(res=>res.json())
                .then((result)=>{
                    let bodies = result;
                     if(result.length === 0){
                        document.getElementById("targetTextClass").classList.add("d-none");
                     }else{
                        document.getElementById("targetTextClass").classList.remove("d-none");
                     }
                    const persistants = bodies.filter(b => b.targetText.displayType === 1);
    
                    bodies = bodies.filter(b => b.targetText.displayType !== 1);
    
                    bodies = [...persistants, ...bodies];
                    setData(bodies);
                });
        
        }
      }, []);

    useEffect(() => {
        data.forEach((item, index) => {
          const el = document.getElementById(`data-body-${index}`);
          const convertedElement = parse(el.innerHTML);
          el.innerHTML = convertedElement;
        });
      }, [data]);

      function parse(content) {
        // Regular Expressions
        const h1 = /^#{2}[^#].*$/gm;
        const h2 = /^#{3}[^#].*$/gm;
        const h3 = /^#{4}[^#].*$/gm;
        const bold = /\*\*[^\*\n]+\*\*/gm;
        const FAQTitle = /\~\~[^\~\n]+\~\~/gm;
        const FAQBody = /\|\|[^\|\n]+\|\|/gm;
        const italics = /[^\*]\*[^\*\n]+\*/gm;
        const link = /\[[\w|\(|\)|\s|\*|\?|\-|\.|\,]*(\]\(){1}[^\)]*\)/gm;
        const lists = /^((\s*((\*|\-)|\d(\.|\))) [^\n]+))+$/gm;
        const unorderedList = /^[\*|\+|\-]\s.*$/;
        const unorderedSubList = /^\s\s\s*[\*|\+|\-]\s.*$/;
        const orderedList = /^\d\.\s.*$/;
        const orderedSubList = /^\s\s+\d\.\s.*$/;
    
        // Example: # Heading 1
        if (h1.test(content)) {
            const matches = content.match(h1);
    
            matches.forEach(element => {
                const extractedText = element.slice(2);
                content = content.replace(element, '<h2>' + extractedText + '</h2>');
            });
        }
    
        // Example: # Heading 2
        if (h2.test(content)) {
            const matches = content.match(h2);
    
            matches.forEach(element => {
                const extractedText = element.slice(3);
                content = content.replace(element, '<h3>' + extractedText + '</h3>');
            });
        }
    
        // Example: # Heading 3
        if (h3.test(content)) {
            const matches = content.match(h3);
    
            matches.forEach(element => {
                const extractedText = element.slice(4);
                content = content.replace(element, '<h4>' + extractedText + '</h4>');
            });
        }
    
        // Example: **Bold**
        if (bold.test(content)) {
            const matches = content.match(bold);
    
            matches.forEach(element => {
                const extractedText = element.slice(2, -2);
                content = content.replace(element, '<strong>' + extractedText + '</strong>');
            });
        }
        // Example: **FAQ Title**
        content = content.replaceAll("\n", "@@&");
        if (FAQTitle.test(content)) {
            const matches = content.match(FAQTitle); // 20
            const matches2 = content.match(FAQBody); // 20
    
            matches.forEach((element, index) => {
                var guid = CreateGuid();
                var extractedText = matches[index];
                var extractedText2 = matches2[index]; //||test||
                const el1 = extractedText.slice(2, -2);
                const el2 = extractedText2.slice(2, -2);
                content = content.replace(extractedText, `<div onclick="showFAQDiv('${guid}');" id="index-${guid}" class="my-2 collapse multi-collapse show FAQClass">
                  <div style="font-size:16px ;margin-bottom: -13px !important; margin-top: -10px !important;">
                      <span id="FAQOperator_index-${guid}" class="targetTextSpan">+</span><span id="FAQTitle_index-${guid}" style="font-weight: 500;">` + el1 + `</span>
                  </div><div class="h6" id="FAQDiv_index-${guid}" style="padding-top:1rem; display:none">` + el2 + `</div>
              </div>`);
                content = content.replace(extractedText2, '');
            });
             
        }
        content = content.replaceAll("@@&", "\n");
    
        // Example: *Italic*
        if (italics.test(content)) {
            const matches = content.match(italics);
    
            matches.forEach(element => {
                const extractedText = element.slice(2, -1);
                content = content.replace(element, ' <em>' + extractedText + '</em>');
            });
        }
    
        if (link.test(content)) {
            const links = content.match(link);
    
            links.forEach(element => {
                const text = element.match(/^\[.*\]/)[0].slice(1, -1);
                const url = element.match(/\]\(.*\)/)[0].slice(2, -1);
    
                content = content.replace(element, '<a href="' + url + '">' + text + '</a>');
            });
        }
    
        if (lists.test(content)) {
            const matches = content.match(lists);
    
            matches.forEach(list => {
                const listArray = list.split('\n');
    
                const formattedList = listArray.map((currentValue, index, array) => {
                    if (unorderedList.test(currentValue)) {
                        currentValue = '<li>' + currentValue.slice(2) + '</li>';
    
                        if (!unorderedList.test(array[index - 1]) && !unorderedSubList.test(array[index - 1])) {
                            currentValue = '<ul>' + currentValue;
                        }
    
                        if (!unorderedList.test(array[index + 1]) && !unorderedSubList.test(array[index + 1])) {
                            currentValue = currentValue + '</ul>';
                        }
    
                        if (unorderedSubList.test(array[index + 1]) || orderedSubList.test(array[index + 1])) {
                            currentValue = currentValue.replace('</li>', '');
                        }
                    }
    
                    if (unorderedSubList.test(currentValue)) {
                        currentValue = currentValue.trim();
                        currentValue = '<li>' + currentValue.slice(2) + '</li>';
    
                        if (!unorderedSubList.test(array[index - 1])) {
                            currentValue = '<ul>' + currentValue;
                        }
    
                        if (!unorderedSubList.test(array[index + 1]) && unorderedList.test(array[index + 1])) {
                            currentValue = currentValue + '</ul></li>';
                        }
    
                        if (!unorderedSubList.test(array[index + 1]) && !unorderedList.test(array[index + 1])) {
                            currentValue = currentValue + '</ul></li></ul>';
                        }
                    }
    
                    if (orderedList.test(currentValue)) {
                        currentValue = '<li>' + currentValue.slice(2) + '</li>';
    
                        if (!orderedList.test(array[index - 1]) && !orderedSubList.test(array[index - 1])) {
                            currentValue = '<ol>' + currentValue;
                        }
    
                        if (!orderedList.test(array[index + 1]) && !orderedSubList.test(array[index + 1]) && !orderedList.test(array[index + 1])) {
                            currentValue = currentValue + '</ol>';
                        }
    
                        if (unorderedSubList.test(array[index + 1]) || orderedSubList.test(array[index + 1])) {
                            currentValue = currentValue.replace('</li>', '');
                        }
                    }
    
                    if (orderedSubList.test(currentValue)) {
                        currentValue = currentValue.trim();
                        currentValue = '<li>' + currentValue.slice(2) + '</li>';
    
                        if (!orderedSubList.test(array[index - 1])) {
                            currentValue = '<ol>' + currentValue;
                        }
    
                        if (orderedList.test(array[index + 1]) && !orderedSubList.test(array[index + 1])) {
                            currentValue = currentValue + '</ol>';
                        }
    
                        if (!orderedList.test(array[index + 1]) && !orderedSubList.test(array[index + 1])) {
                            currentValue = currentValue + '</ol></li></ol>';
                        }
                    }
    
                    return currentValue;
                }).join('');
    
                console.log(formattedList);
                content = content.replace(list, formattedList);
            });
        }
    
        return content.split('\n').map(line => {
            if (!h1.test(line) && !h2.test(line) && !h3.test(line) && !unorderedList.test(line) && !unorderedSubList.test(line) && !orderedList.test(line) && !orderedSubList.test(line)) {
                return line.replace(line, '<p>' + line + '</p>');
            }
        }).join('');
    }

    function CreateGuid() {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    }
    
    
    function setTargetText(item,index){
            return <div key={index} id={`index-${index}`} className={`my-2 collapse multi-collapse ${item.targetText.displayType === 0 ? '' : !item.users?.find(u => u != null && u.userId === userId)?.dismissed ?? true ? 'show' : ''}`}>
            <div className={`h6 ${item.targetText.displayType === 1 ? '' : 'tile'} ${(() => {
                switch (item.targetText.displayType) {
                  case 3:
                    return 'alert-secondary';
                  case 2:
                    return 'alert-success';
                  case 0:
                    return 'alert-primary';
                  case 1:
                    return '';
                  default:
                    return '';
                }
              })()}`}
            >
              <h2>
                {item.title}
                {item.targetText.displayType !== 1 && (
                  <span>
                    <button
                      type="button"
                      onClick={() => onToggleTargetText(item.targetTextBodyId, `index-${index}`)}
                      className={`close ${((item.users?.find(u => u != null && u.userId === userId)?.dismissed ?? false) || (item.targetText.displayType === 0)) ? 'd-none' : ''} ${(() => {
                          switch (item.targetText.displayType) {
                            case 3:
                              return 'text-secondary';
                            case 2:
                              return 'text-success';
                            case 0:
                              return 'text-primary';
                            case 1:
                              return '';
                            default:
                              return '';
                          }
                        })()}`}
                      data-toggle="collapse"
                      data-target={`#index-${index}`}
                    >
                      <span> <FaRegTimesCircle  /></span>
                    </button>
                  </span>
                )}
              </h2>
              <hr />
              <div id={`data-body-${index}`} dangerouslySetInnerHTML={{ __html: item.bodyText }}>
               
              </div>
            </div>
          </div>;
        }

    function setPersistantTargetText(item,index){
        const isIntro = item.targetText.displayType === 0;
        const isDismissed = !item.users?.find(u => u != null && u.userId === userId)?.dismissed ?? true;
        const hideButton = !isIntro && isDismissed;
      
            return (
            <button
                style={{ position: 'relative' , color:'#007bff',fontSize:"1.375rem", marginTop:"-3px" }}
                className={`btn btn-link-dark-blue float-right ${hideButton ? 'd-none' : ''}`}
                title={item.title}
                type="button"
                data-toggle="collapse"
                data-target={`#index-${index}`}
                onClick={() => onToggleTargetText(item.targetTextBodyId, `index-${index}`)}
            >
                <FaInfoCircle />
            </button>
            );
        }

        const onToggleTargetText = (targetTextBodyId, dataTarget) => {
            var elements = document.querySelectorAll(`[data-target='#${dataTarget}']`);
            elements.forEach(function (element, index) {
                        if (element.classList.contains("d-none")) {
                            element.classList.remove("d-none");
                        } else {
                            element.classList.add("d-none");
                        }
                    });
                    if (elements[1].classList.contains("d-none")) {
                            document.getElementById(dataTarget).classList.add('collapse');
                    }else{
                        document.getElementById(dataTarget).classList.remove('collapse');
                    }

                    fetch(config.url.BASE_URL+`Home/ToggleTargetTextVisibility/${targetTextBodyId}/${userId}`,{
                        method:'Get',
                        headers:{
                            'Accept':'application/json',
                            'Content-Type':'application/json'
                        }
                        })
                        .then(res=>res.json())
                        .then((result)=>{
                             
                        }
                        )

                    };

    return(
            <div className="coverD" id="targetTextClass">
                 <div class="targetTextClass">
                     {data.filter(b => b.targetText.displayType !== 1).map((item, index)=> (setPersistantTargetText(item, index)))}
                     {data.map((item, index)=> (setTargetText(item, index)))}
                </div>
            </div>
        )
}
export default TargetText;
import { useIntl } from 'react-intl';
import { RSAKey } from "jsencrypt/lib/lib/jsbn/rsa";

var PrivateKey = localStorage.getItem('privateKeyCookie');
var PublicKey = localStorage.getItem('publicKeyCookie');

    const startTag = "<encrypt>",
    endTag = "</encrypt>";
    const cryptico = window.cryptico;


function deserializeRSAKey(key) {
    try {
    let json = JSON.parse(key);
    let rsa = new RSAKey();
    rsa.setPrivateEx(json.n, json.e, json.d, json.p, json.q, json.dmp1, json.dmq1, json.coeff);
    return rsa;
} catch (exceptionVar) {
    window.location.reload();
  }
}
function getCharactersInBetween(input, startsWith, endsWith) {
    let result = [];
    var inputLength;
    do {
        var startIndex = input.indexOf(startsWith);
        if (startIndex === -1) break;
   
        var endIndex = input.indexOf(endsWith) + endsWith.length;
        result.push(input.substring(startIndex, endIndex));
        inputLength = input.length;
        input = input.substring(endIndex);
    } while (endIndex < inputLength);
    return result;
   }
function RemoveTags(input) {
return input.replace(startTag, "").replace(endTag, "");
}
export function useFormatMessage(messageId) {
    return useIntl().formatMessage({ id: messageId })
}
export function encryptString(input) {
return "<encrypt>"+cryptico.encrypt(input, PublicKey).cipher+"</encrypt>";
}


export function encryptStringWithoutTags(input) {
    return cryptico.encrypt(input, PublicKey).cipher;
    }

export function encryptStringWithoutTagsForUser(input,doctorsPublicKey) {
    return cryptico.encrypt(input, doctorsPublicKey).cipher;
    }

    export function encryptStringForDoctor(input,doctorsPublicKey) {
        return "<encrypt>"+cryptico.encrypt(input, doctorsPublicKey).cipher+"</encrypt>";
        }

export function decryptString(input) {
    var encryptedValues = getCharactersInBetween(input, startTag, endTag);
    var userPrivateKey = deserializeRSAKey(PrivateKey);
    if (encryptedValues.length) {
        //iterates over each data surrounded with specified tags and tries to replace with decrypted value
        for (var i = 0; i < encryptedValues.length; i++) {
            var decrypted = cryptico.decrypt(RemoveTags(encryptedValues[i]), userPrivateKey);
            if (decrypted.status === "success")
                input = input.replace(encryptedValues[i], decrypted.plaintext);
            else {
                input = input.replace(encryptedValues[i], RemoveTags(encryptedValues[i]));
            }
        }
    }
    return input;
   }

   export function decryptStringWithoutTags(input) {
    var userPrivateKey = deserializeRSAKey(PrivateKey);
    if (input.length) {
        //iterates over each data surrounded with specified tags and tries to replace with decrypted value
        for (var i = 0; i < input.length; i++) {
            var decrypted =  cryptico.decrypt(input, userPrivateKey);
            if (decrypted.status === "success"){
                input = decrypted.plaintext;
            }
        }
    }
    return input;
   }

   export function getCurrency(culture){
    if(culture==="da-DK"){
        return "kr.";
    }else{
        return "€";
    }
}
     
   export function fixDate(input) {
    var datearray=[];
    var splitedChar="";
    if(input.includes("-")){
        datearray = input.split("-");
        splitedChar="-";
    }else{
      datearray = input.split(".");
      splitedChar = ".";
    }
    if(datearray[0].length === 4){
        input=datearray[2]+splitedChar+datearray[1]+splitedChar+datearray[0];
    }else{
        input=datearray[0]+splitedChar+datearray[1]+splitedChar+datearray[2];
    }
    if(datearray[0].length === 1){
        datearray[0]="0"+datearray[0];
        input=datearray[0]+splitedChar+datearray[1]+splitedChar+datearray[2];
    }
    if(datearray[1].length === 1){
        datearray[1]="0"+datearray[1];
        input=datearray[0]+splitedChar+datearray[1]+splitedChar+datearray[2];
    }
    let language = localStorage.getItem('lang');
    if(language==='en'){
     if(input.includes("-")){
        input = input.replaceAll("-",".");
     }
   }else{
     if(input.includes(".")){
        input = input.replaceAll(".","-");
     }
   }
    return input;
   }

   export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  export function fixDateBackEnd(input) {
    var datearray=[];
    var splitedChar="";
    if(input.includes("-")){
        datearray = input.split("-");
        splitedChar="-";
    }else{
      datearray = input.split(".");
      splitedChar = ".";
    }
    input=datearray[1]+splitedChar+datearray[0]+splitedChar+datearray[2];
    return input;
   }

 export function validateCPRNumber(cprNumber,bday,gender) {
    var regex = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/gm;
    var isValid = true;
    if (regex.test(cprNumber)) {
        var dd = bday.substr(0, 2);
        var mm = bday.substr(3, 2);
        var yy = bday.substr(8, 2);
        var cpr6digit = cprNumber.substr(0, 6);
        var cprGender = cprNumber.substr(10, 1);
        if (cpr6digit != dd + mm + yy) {
            isValid = false;
        }
        if (gender == "0") {
            if (cprGender % 2 == 0) {
                isValid = false;
            }
        }
        if (gender == "1") {
            if (cprGender % 2 != 0) {
                isValid = false;
            }
        }
    } else {
        isValid = false;
    }
    return isValid;
}



import React from "react";
import NewNavBar from "../navbar/new_navbar.js";
import PatientBookingList from "../bookings/patient_booking_list.js";
import DoctorBookingList from "../bookings/doctor_booking_list.js";
import {FormattedMessage,useIntl} from 'react-intl';
import FrontPage from "./front_page.js";
import { useLocation } from "react-router-dom";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import Roles from '../../roles';
import axios from "../../context/axios_instance";
import * as Functions from '../../functions/functions';
import Footer from "../footer/footer.js";
import TargetText from "../targettext/target_text.js";
const Dashboard = () => {
    const isLoggedIn = localStorage.getItem('access_token');
    const roles = localStorage.getItem('roles');
    alertify.set('notifier','position', 'top-right');
    const intl = useIntl();
    const {state} = useLocation();
    const location = useLocation();
    let hiddenDiv="";
    if(state !==null ){
        if(state.props===2)
        {
            alertify.success(intl.formatMessage({id: 'BookingConfirmMessage'}));
            hiddenDiv=<div id="BookingCreatedModal"></div>;
            window.history.replaceState({},null);
        }
    }
    if(window.location.href.includes('bookingId')){
        const urlParams = new URLSearchParams(window.location.search);
        const bookingId = urlParams.get('bookingId');
        if(bookingId !==null){
            hiddenDiv=<div id="BookingCreatedModal"></div>;
            axios.get(`Patient/Account/GetPatientProfile`)
            .then(
              (result) => {
                        console.log(result.data);
                        const bookingModel= {
                                paymentId:bookingId,
                                pEmail:Functions.decryptString(result.data.email),
                                fullName:Functions.decryptString(result.data.fullName),
                                bDay:Functions.decryptString(result.data.birthday),
                                houseCallAddress:localStorage.getItem("houseCallAddress")
                        };
                        localStorage.removeItem("houseCallAddress");
                        axios.post(`/Patient/Home/PaymentReturn`,bookingModel)
                            .then(
                            (result) => {
                                console.log(result.data);
                                    if(result.status == 200){
                                        alertify.success(intl.formatMessage({id: 'BookingConfirmMessage'}));
                                        setTimeout(function () {
                                            window.location.href='/dashboard';
                                        }, 5000);
                                    }
                            }
                            )
                    }
                )
        }
    }
    
    var bookings;
    if (isLoggedIn != null && roles.includes(Roles.Patient)) {
        bookings = <PatientBookingList />;
    }else{
        bookings = <DoctorBookingList />;
    }
    return (
        <div>
             <NewNavBar />
            <div className="pageD container">
            <TargetText name= {location.pathname}/>
           <FrontPage />
            <div className="coverD">
                <h4><FormattedMessage id="Bookings"/></h4>
                <div className="bookingCard">{bookings}</div>
            </div>
            {hiddenDiv}
            <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
            </div>
        </div>
    </div>
        )
  };
  export default Dashboard;
